<app-navbar 
  class="navabr_component"
  *ngIf="
    !(
      router.url === '/' ||
      router.url === '/planning' ||
      router.url === '/forgotPassword' ||
      router.url ==='/forbidden-access' ||
      router.url ==='/page-not-found' ||
      router.url ==='/in-development' ||
      router.url.startsWith('/resetPassword')
    )
  "
></app-navbar>
<router-outlet></router-outlet>
<!-- <app-footer class="footer_component"
*ngIf="
    !(
      router.url === '/' ||
      router.url === '/forgotPassword' ||
      router.url ==='/forbidden-access' ||
      router.url ==='/page-not-found' ||
      router.url.startsWith('/resetPassword')
    )
  "></app-footer> -->

<div *ngIf="!isLoadedFacturees">
  <span class="loader"></span>
</div>
<div class="facturees_globale" *ngIf="isLoadedFacturees">
  <div class="facturees_block_1">
    <div class="facturees_titles">
      <h1>Interventions Facturées</h1>
    </div>
    <div class="facturees_buttons">
      <a (click)="openDialogFacturees()">
        <img src="../assets/icon/icon_add.svg" alt="icon_aadd" />
        <span class="tooltip">Ajouter</span>
      </a>
    </div>
  </div>

  <div class="export_search">
    <button class="excel_button btn" (click)="saveExcel()">Excel</button>
    <form>
      <div class="mb-3 row">
        <div class="col-xs-3 col-sm-auto">
          <input
            id="table-filtering-search"
            placeholder="Rechercher ..."
            class="form-control"
            type="text"
            (change)="onSearchKeyUp($event)"
          />
        </div>
      </div>
    </form>
  </div>

  <div class="table_global">
    <div class="table-responsive">
      <table class="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">ID RDV</th>
            <th scope="col">Code Tech</th>
            <th scope="col">Statut RDV</th>
            <th scope="col">Type Tache</th>
            <th scope="col">Département</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let facturee of facturees">
            <td class="visibility_facturees">
              {{ facturee.idRdv }}
              <img
                (click)="viewFactureesDetails(facturee)"
                src="../assets/icon/file.png"
                alt="icon_view"
              />
            </td>
            <td>{{ facturee.techs.codeTech }}</td>
            <td>{{ facturee.statutRdv }}</td>
            <td>{{ facturee.typeTache }}</td>
            <td>{{ facturee.departement }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="table_rows">
    <span *ngIf="facturees.length > 0"
      >Affichage de 1 à {{ facturees.length }} sur
      {{ facturees.length }} entrées</span
    >
    <span *ngIf="facturees.length == 0"
      >Affichage de 0 à {{ facturees.length }} sur
      {{ facturees.length }} entrées</span
    >
  </div>
</div>

<ng-template let-data #factureeDialog>
  <div class="card_title">
    <h2 mat-dialog-title>Details Intervention Facturée</h2>
  </div>
  <div mat-dialog-content class="card_content">
    <div class="row">
      <div class="col-md-4">
        <label for="" class="form-label">ID RDV</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="viewedFacturee.idRdv"
          disabled
        />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <label for="" class="form-label">Code Tech</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="viewedFacturee.techs.codeTech"
          disabled
        />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <label for="" class="form-label">Statut RDV</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="viewedFacturee.statutRdv"
          disabled
        />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <label for="" class="form-label">Type Tache</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="viewedFacturee.typeTache"
          disabled
        />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <label for="" class="form-label">Département</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="viewedFacturee.departement"
          disabled
        />
      </div>
    </div>
    <br />
  </div>
  <div mat-dialog-actions class="cancel_button">
    <button mat-button (click)="onCancelTechDialog()" class="btn btn-primary">
      Annuler
    </button>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';
import { InterventionFree } from '../models/InterventionFree';

@Injectable({
  providedIn: 'root',
})
export class IntersService {
  private baseUrl = `${environment.BASE_URL}/inters`;
  constructor(
    private http: HttpClient,
  ) { }

  uploadRealisees(jsonFile: any): Observable<JSON> {
    return this.http.post<any>(`${this.baseUrl}/uploadRealisees`, jsonFile);
  }

  uploadFacturees(jsonFile: any): Observable<JSON> {
    return this.http.post<any>(`${this.baseUrl}/uploadFacturees`, jsonFile);
  }

  uploadFree(jsonFile: any): Observable<JSON> {
    return this.http.post<any>(`${this.baseUrl}/createFrees`, jsonFile);
  }

  updateFree(free:InterventionFree): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/updateFree`, free);
  }

  getAllRealisees(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/allRealisees`, );
  }

  getAllFree(): Observable<InterventionFree[]> {
    return this.http.get<InterventionFree[]>(`${this.baseUrl}/allFree`,);
  }

  affectTechsToFree(affectedFree: InterventionFree[],idTech:number): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/affectTechsToFree/${idTech}`, affectedFree, );
  }

  getPlanFreeById(id: number): Observable<InterventionFree[]> {
    return this.http.post<InterventionFree[]>(`${this.baseUrl}/getPlanFreeById/${id}`, id, );
  }

  getDashboardFree(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/dashboardFree`, );
  }

  orderFreesByDistances(jsonFile: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/orderFreesByDistance`, jsonFile, );
  }

  getAdressByAllDep(deps: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/adressesByAllDep`, deps, );
  }



  getStatPreviousYear() {
    return this.http.get<any>(`${this.baseUrl}/statPreviousYear`, );
  }

  getAllFacturees(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/allFacturees`, );
  }

  getRealiseesNonFacturees(jsonDate: any): Observable<JSON> {
    return this.http.post<any>(`${this.baseUrl}/realiseesNonFacturees`, jsonDate, );
  }

  getFactureesNonRealisees(jsonDate: any): Observable<JSON> {
    return this.http.post<any>(`${this.baseUrl}/factureesNonRealisees`, jsonDate, );
  }
}

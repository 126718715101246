import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IntersService } from 'src/app/core/services/inters.service';
import { AjoutFactureesComponent } from '../ajout-facturees/ajout-facturees.component';
import { ExcelService } from 'src/app/core/services/excel.service';

@Component({
  selector: 'app-liste-facturees',
  templateUrl: './liste-facturees.component.html',
  styleUrls: ['./liste-facturees.component.css'],
})
export class ListeFactureesComponent implements OnInit {
  statusMessage: string = '';
  allFacturees: any = [];
  facturees: any = [];
  isLoadedFacturees: boolean = false;
  dialogRef: any;
  viewedFacturee: any;

  @ViewChild('factureeDialog') factureeDialog = {} as TemplateRef<string>;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private intersService: IntersService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    this.loadFacturees();
  }

  openSnackBarSuccess(message: string) {
    this.snackBar.open(message, '', {
      duration: 3000, panelClass: ['green-snackbar'],
    });
  }

  openSnackBarFailure(message: string) {
    this.snackBar.open(message, '', {
      duration: 3000, panelClass: ['red-snackbar'],
    });
  }

  openDialogFacturees(): void {
    const dialogRef = this.dialog.open(AjoutFactureesComponent, {
      width: '45%',
      height: '35%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'Confirm') {
        (this.statusMessage = 'Interventions Facturées ajoutés Success!'),
          this.openSnackBarSuccess(this.statusMessage);
        this.loadFacturees();
      } else if (result == 'Error') {
        (this.statusMessage = 'Interventions Facturées non ajoutés Fail!'),
          this.openSnackBarFailure(this.statusMessage);
        this.loadFacturees();
      } else {
        this.loadFacturees();
      }
    });
  }

  loadFacturees() {
    this.intersService.getAllFacturees().subscribe(
      (response: any) => {
        this.allFacturees = response.content;
        this.facturees = this.allFacturees
        this.isLoadedFacturees = true;
      },
      (error: any) => {
        (this.statusMessage = 'Chargement des interventions Facturées a échouée!'),
          this.openSnackBarFailure(this.statusMessage);
        this.isLoadedFacturees = false;
      }
    );
  }

  sortAllFacturees(list: any[], column: string): any[] {
    let sortedArray = list.sort((a, b) => {
      if (a[column] > b[column]) {
        return 1;
      }
      if (a[column] < b[column]) {
        return -1;
      }
      return 0;
    });
    return sortedArray;
  }

  onSearchKeyUp(event: any): any {
    let texte = (event.target as HTMLInputElement).value;
    if (texte != "") {
      this.searchFactureesByString(texte);
    } else {
      this.facturees = this.allFacturees
    }
  }

  search(string: string) {
    let results: any = [];
    this.facturees = this.allFacturees
    for (var i = 0; i < this.facturees.length; i++) {
      for (var key in this.facturees[i]) {
        if (typeof this.facturees[i][key] === 'object') {
          if (this.facturees[i][key] != null)
            for (let subkey in this.facturees[i][key]) {
              if (typeof this.facturees[i][key][subkey] === 'string' && this.facturees[i][key][subkey].toLowerCase().includes(string.toLowerCase())) {
                results.push(this.facturees[i]);
                break;
              }
            }
        }
        if (typeof this.facturees[i][key] === 'string' && this.facturees[i][key].toLowerCase().includes(string.toLowerCase())) {
          results.push(this.facturees[i]);
          break;
        }
      }
    }
    return results;
  }

  searchFactureesByString(string: string): any {
    this.facturees = this.search(string);
    if (this.facturees.length == 0) {
      this.openSnackBarFailure('Pas des utilisateurs avec les informations cherchées !');
    }
  }

  saveExcel() {
    this.excelService.exportAsExcelFile(this.fixFactureeExcel(), 'liste_facturees');
  }

  fixFactureeExcel(): any {
    let factureesPreModif = [...this.facturees]
    return factureesPreModif.map((newObject: any) => {
      const downloadedFacturee = { ...newObject }
      if (downloadedFacturee.techs != null) {
        downloadedFacturee.techs = downloadedFacturee.techs.codeTech
      }
      return downloadedFacturee;
    });
  }

  viewFactureesDetails(facturee: any) {
    this.viewedFacturee = facturee;
    this.dialogRef = this.dialog.open(this.factureeDialog,
      { data: this.viewedFacturee, height: '50%', width: '35%' });
  }

  onCancelTechDialog() {
    this.dialogRef.close();
  }
}
<app-main-wrapper>
    <div class="w-100  mb-3 pb-4 pt-5"  style="font-size: 0.8rem;" >
        <span style="color:var(--g1)"><h5>Tableau de board</h5></span>
      </div>
        <div class="row mb-5 pb-4">
          <div class="col-md-8 col-xl-3">
              <div class="card x-shadow mb-3">
                  <div style="width: 100%;">
                      <div class="p-2 d-flex justify-content-between">
                          <div class="p-2">
                              <div class=""><strong>Intervenstions Total</strong></div>
                              <div class=""><small>Tous les années</small></div>
                          </div>
                          <div class="p-2">
                              <div class="" style="color: var(--primary);font-size: 120%;"><strong>{{numberOfInterventions}}</strong></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-8 col-xl-3">
              <div class="card x-shadow mb-3">
                  <div style="width: 100%;">
                      <div class="p-2 d-flex justify-content-between">
                          <div class="p-2">
                              <div class=""><strong>Techniciens</strong></div>
                              <div class=""><small>Tous les années</small></div>
                          </div>
                          <div class="p-2" style="color: var(--primary);font-size: 120%;">
                              <div class=""><strong>{{numberOfTechnicians}}</strong></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-8 col-xl-3">
              <div class="card x-shadow mb-3 ">
                  <div style="width: 100%;">
                      <div class="p-2 d-flex justify-content-between">
                          <div class="p-2">
                              <div class=""><strong>Departments</strong></div>
                              <div class=""><span>Tous les années</span></div>
                          </div>
                          <div class="p-2" style="color: var(--primary);font-size: 120%;">
                              <div class=""><strong>{{numberOfDepartments}}</strong></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-8 col-xl-3">
            <div class="card x-shadow mb-3 ">
                <div style="width: 100%;">
                    <div class="p-2 d-flex justify-content-between">
                        <div class="p-2">
                            <div class=""><strong>Clients</strong></div>
                            <div class=""><small>Tous les années</small></div>
                        </div>
                        <div class="p-2" style="color: var(--primary);font-size: 120%;">
                            <div class=""><strong>?</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-md-10 col-xl-6">
            <div class="card x-shadow mb-3">
            <apx-chart  class="p-2 pt-3" style="width: 100%;"
            [series]="chartTechnicianOptions.series"
            [chart]="chartTechnicianOptions.chart"
            [xaxis]="chartTechnicianOptions.xaxis"
            [title]="chartTechnicianOptions.title"
            [colors]="chartTechnicianOptions.colors"
            [dataLabels]="chartTechnicianOptions.dataLabels"
            [plotOptions]="chartTechnicianOptions.plotOptions"
            ></apx-chart>
            </div>
        </div>
        <div class="w-md-100 col-md-10 col-xl-6">
          <div class="card x-shadow mb-3">
            <apx-chart class="p-2 pt-3" style="width: 100%;"
            [series]="chartStatusOptions.series"
            [chart]="chartStatusOptions.chart"
            [xaxis]="chartStatusOptions.xaxis"
            [title]="chartStatusOptions.title"
            [colors]="chartStatusOptions.colors"
            [dataLabels]="chartStatusOptions.dataLabels"
            [plotOptions]="chartStatusOptions.plotOptions"
            ></apx-chart>
            </div>
          </div>
        </div>
</app-main-wrapper>
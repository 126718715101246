import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service';

export const RoleGuard: CanActivateFn = (route, state) => {
  const userRole= TokenStorageService.getRole();
  if(userRole && route.data['roles'].includes(userRole)){
    console.log('route data',route.data['roles']);
    return true;
  }
  inject(Router).navigateByUrl('/forbidden-access');
  return false;
};

<div class="h-100 w-100 d-flex flex-column justify-content-between p-4">
  <div class="p-2 text-center">
    <strong><h4>Nouveau Utilisateur</h4></strong>
  </div>
  <div class="text-center p-2" style="font-size: 0.9;">Saisir les champs pour ajouter un nouveau utilisateur</div>
    <form 
      [formGroup]="userForm"
      class="w-100 p-4 pb-0 mb-4"
    >
      <div class="p-1">
            <label for="email" class="form-label" style="color:var(--c3)">Email Utilisateur</label>
            <input [disabled]="loading"
              class="form-control"
              formControlName="email"
              
              [ngClass]="{
                'is-invalid': f['email'].touched && f['email'].invalid
              }"
            />
              <span class="invalid-feedback" *ngIf="f['email'].hasError('required')">*Mettez une adresse E-mail valide</span>
      </div>
      <div class="p-1 w-100">
            <label for="role" class="form-label" style="color:var(--c3)">Nom </label>
            <div class="col-md-9 w-100">
              <select
                class="form-select w-100"
                formControlName="role"
                [ngClass]="{
                  'is-invalid': f['role'].touched && f['role'].invalid
                }"
              >
                <option value="" disabled selected>Choisir un role</option>
                <option *ngFor="let role of allRoles" [value]="role.name">
                  {{ role.description }}
                </option>
              </select> 
              </div>
      </div>
      <div class="p-1">
            <label for="tech" class="form-label" style="color:var(--c3)">E-mail</label>
            <select class="form-select" formControlName="tech">
              <option value="" disabled selected>Choisir un Technicien</option>
              <option *ngFor="let tech of allTechs" [value]="tech.id">
                {{ tech.prenom }} {{ tech.nom }}
              </option>
            </select>
      </div>
    </form>
    <div class="w-100 d-flex justify-content-center">
      <div class="w-50 d-flex justify-content-center">
        <button  id="closeDialogBtn" class="btn-light btn" type="button" mat-dialog-close (click)="onCancelClick()">Annuler</button>
        <button    [disabled]="loading"   (click)="submitUser()" class="btn-confirm btn"  >Ajouter</button>
        <div *ngIf="loading" class="spinner-square mt-1 ms-2">
          <div class="square-1 square"></div>
          <div class="square-2 square"></div>
          <div class="square-3 square"></div>
        </div>
      </div>
  </div>
</div>
<div class="main">
 <div class="main-content h-100">
  <div class="w-100  mb-3 pb-4 pt-5" style="font-size: 0.8rem;">
    <div class=" w-100 mb-2 ps-0 p-2 d-flex justify-content-between">
      <span style="color:var(--g1)"><h5>Interventions free</h5></span>
      <button class="btn-primary btn" [disabled]="!isLoadedFree"  data-bs-toggle="modal" data-bs-target="#ajouterInterventions"  style="font-size: 0.8rem;"><i class="fi fi-rs-plus  pe-1 pt-1"></i> <span>Nouvelles Interventions</span> </button>
    </div>
    <div class="card">
      <div class="d-flex w-100">
        <div class="w-100 p-2">
          <ng-multiselect-dropdown style="background-color: var(--c2) !important;"
          [placeholder]="'Les types'"
          [settings]="labelLongsDropdownSettings"
          [data]="LabelLongfrees"
          [(ngModel)]="selectedLabelLong"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          [disabled]="!isLoadedFree"
        >
        </ng-multiselect-dropdown>
        </div>
        <div class="w-100 p-2">
          <ng-multiselect-dropdown
          [placeholder]="'Les techniciens'"
          [settings]="techniciansDropdownSettings"
          [data]="actifTechs"
          [(ngModel)]="selectedTechs"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          [disabled]="!isLoadedFree"
        >
        </ng-multiselect-dropdown>
        </div>
        <div class="w-50 p-2">
          <ng-multiselect-dropdown
          [placeholder]="'les departments'"
          [settings]="departmentsDropdownSettings"
          [data]="interventionFreeDepartments"
          [(ngModel)]="selectedDep"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          [disabled]="!isLoadedFree"
        >
        </ng-multiselect-dropdown>
        </div>
        <div class="w-50 p-2">
          <ng-multiselect-dropdown
          [placeholder]="'Les status'"
          [settings]="statusDropdownSettings"
          [data]="status"
          [(ngModel)]="selectedStatus"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          [disabled]="!isLoadedFree"
        >
        </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="p-2  d-flex justify-content-end"  style="font-size: 0.8rem;">
        <button class="btn-secondary btn me-3" [disabled]="!isCheckedFree" (click)="openListTechs()"  style="font-size: 0.8rem;"><i class="fi fi-rs-hammer pe-2" ></i> Affecter</button>
        <button class="btn-secondary btn me" [disabled]="!isLoadedFree" (click)="saveExcel()" style="font-size: 0.8rem;"><i class="fi fi-rs-file-excel pe-2"></i> Excel </button>
      </div>
    </div>
  </div> 
  <div *ngIf="!isLoadedFree">
    <span class="loader"></span>
  </div>
  <div  *ngIf="isLoadedFree">
    <div class="card p-4" style="font-size: 0.8rem;">
      <table *ngIf="allFree" [dtOptions]="dtOptions" datatable class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">
              <input
                type="checkbox"
                class="form-check-input"
                id="selectAllFree"
                [checked]="isAllCheckBoxChecked()"
                (change)="selectAllFree($event)"
              />
            </th>
            
            <th scope="col">
              Type MalFaçon
            </th>
            <th scope="col">
              Réf Ticket hlp
            </th>
            <th scope="col">
              Label Long
            </th>
            <th scope="col">
              Label Court
            </th>
            <th scope="col">
              Dép
            </th>
            <th scope="col">Adresse</th>
            <th scope="col">Tech</th>
            <th  scope="col">Nb. docs</th>
            <th scope="col">Détails</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let filterFree of filterFree()">
            <td>
              <input
                type="checkbox"
                class="form-check-input"
                [(ngModel)]="filterFree.checked"
                (change)="selectFree($event, filterFree)"
              />
            </td>
            <td class="visibility_free">
              {{ filterFree.typeMalfacon }}
            </td>

            <td>{{ filterFree.refTicketHlp }}</td>
            <td>{{ filterFree.labelLongFree.label }}</td>
            <td>{{ filterFree.labelCourt }}</td>
            <td>{{ filterFree.department?.code }}</td>
            <td>{{ filterFree.adresse }}</td>
            <td>{{ filterFree.techs?.nom }} {{ filterFree.techs?.prenom }}</td>
            <td>{{ filterFree.docs.length}} sur 3 documents</td>
            <td (click)="viewFree(filterFree)" class="pointer"><i class="fi fi-br-info" ></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> 
 </div>
</div>

<ng-template let-date #affectTechDialog>
  <div class="card m-4 p-4 d-flex">
    <table *ngIf="techs" [dtOptions]="dtOptions" class="table table-striped table-hover" datatable>
      <thead>
        <tr>
          <th></th>
          <th scope="col">Code Tech</th>
          <th scope="col">E-mail</th>
          <th scope="col">Prénom & Nom</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let tech of actifTechs; let i = index">
          <tr [ngClass]="{'selected': selectedTechIndex === i}" (click)="setAffectedTechs(tech,i)">
            <td>
              <ng-container>{{ i + 1 }}</ng-container>
            </td>
            <td>{{ tech.codeTech }}</td>
            <td>{{ tech.email }}</td>
            <td>{{tech.prenom }} {{tech.nom}}</td>
            <td><img *ngIf="selectedTechIndex === i" src="assets/icon/ok.svg"></td>
          </tr>
        </ng-container>
        
      </tbody>
    </table>
  </div>
  <div class="d-flex p-2 justify-content-center w-100">
    <button
        mat-dialog-close
          class="me-4 btn-light btn"
          (click)="cancelAffectFree()"
        >
          Annuler
        </button>
          <button 
          class="btn-confirm  btn"
          (click)="confirmAffectFree()"
          [disabled]="!isAffectedTech"
        >
          Confirmer
        </button>    
        </div>
</ng-template>

<ng-template let-data #freeDetailsDialog >
  <div class=" p-2 pb-0 d-flex align-items-between flex-column" style="height: 100%;">
    <div class="w-100 text-center mb-2" style="font-size: x-large;" ><strong>Details d'intervention</strong></div>
    <div class=" m-2 mb-0" style="width: 98%; font-size:80%">
      <div class="row ms-1 p-1 d-flex flex-row justify-content-between">
        <div class=" col-4  mb-3"><strong>Intervention de type  {{viewedFree.typeMalfacon}}</strong></div>
          <div class="col-4  mb-3"><strong>{{viewedFree.refTicketHlp}}</strong></div>
          <div class="col-4  mb-3">
            <strong  >Département :</strong>
            <strong ><span >{{viewedFree.department.code}}<span *ngIf="!viewedFree.department">-</span></span></strong>
          </div>
      </div>
    </div>
    <div class="  m-4 mt-1 mb-3" style="width: 96%;font-size:80%">
      <div class="d-flex flex-row row"> 
        <div class="col ms-1  " ><strong >Adresse PTO :</strong><span>{{viewedFree.adressePto}}</span> <span *ngIf="!viewedFree.adressePto">Pas d'adresse PTO</span></div>
        <div class="col ms-1  " ><strong >Adresse PBO :</strong> <span >{{viewedFree.adressePbo}}</span> <span *ngIf="!viewedFree.adressePbo">Pas d'adresse PBO</span></div>
        <div class="col ms-1  "><strong >Adresse PM :</strong><span >{{viewedFree.adressePm}} </span><span *ngIf="!viewedFree.adressePm">Pas d'adresse PM</span></div>
      </div>
      <div class="d-flex flex-row row"> 
        <div class="col ms-1 "><strong >Réference PTO :</strong><span >{{viewedFree.refPto}}</span> <span *ngIf="!viewedFree.refPto">Pas réference PTO</span></div>
        <div class="col ms-1 "><strong >Réference PBO :</strong><span>{{viewedFree.refPbo}}</span> <span *ngIf="!viewedFree.refPbo">Pas réference PBO</span></div>
        <div class="col ms-1 "><strong >Réference PM :</strong><span >{{viewedFree.refPm}}</span> <span *ngIf="!viewedFree.refPm">Pas réference PM</span></div>
      </div>
    </div>
    <div  class="row m-2 mb-3"  style="width: 98%;font-size:80%">
      <div *ngIf="viewedFree.detailDemande" class="col ms-2">
        <span><strong >Description:</strong></span>
          <span [innerText]="viewedFree.detailDemande">
            <!-- {{viewedFree.detailDemande}} -->
          </span>
      </div>
    </div>
  
    <div class="d-flex flex-row row m-2 mt-3  mb-0  d-flex justify-content-arround"  style="width: 98%;font-size:80%">
      <div class="col ms-3 p-1">
        <div><div class="pb-3"><strong>Long Labels</strong></div>
        <select style="font-size:90%"
        *ngIf="viewedFree.labelCourt == 'Individuelle'"
          class="form-select"
          [(ngModel)]="viewedFree.labelLongFree.label"
        disabled="true">
        <option [ngValue]="viewedFree.labelLongFree.label" selected>{{viewedFree.labelLongFree.label}}</option>
        </select>
          <select style="font-size:90%"
          *ngIf="viewedFree.labelCourt == 'Collective'"
          class="form-select"
          [(ngModel)]="viewedFree.labelLongFree"
        >
        <option [ngValue]="viewedFree.labelLongFree" selected>{{viewedFree.labelLongFree.label}}</option>
        <ng-container *ngFor="let labelLong of LabelLongfrees">
          <option *ngIf="labelLong.id!=viewedFree.labelLongFree.id" [ngValue]="labelLong">
            {{labelLong.label}}
          </option>
        </ng-container>
        </select></div>
      </div>
      <div class="col ms-3 p-1">
        <div><div class="pb-3"><strong>Techniciens</strong></div>
          <select style="font-size:90%" class="form-select" [(ngModel)]="viewedFree.techs">
            <ng-container>
              <option *ngIf="viewedFree.techs" [ngValue]="viewedFree.techs" selected>{{ viewedFree.techs.nom }} {{ viewedFree.techs.prenom }}</option>
            </ng-container>
            <ng-container *ngFor="let tech of actifTechs"> 
              <option *ngIf="tech.id!=viewedFree.techs?.id" [ngValue]="tech">
                {{ tech.nom }} {{ tech.prenom }}
              </option>
            </ng-container>
        </select></div>
      </div>
      <div class="col  ms-3 p-1">
        <div><div class="pb-3"><strong>Status</strong></div>  
          <select style="font-size:90%" class="form-select" [(ngModel)]="viewedFree.status">
          <option value="INIT_INTER">En attente</option>
          <option value="REALISE_OK">Réussi</option>
          <option value="REALISE_NOK">Échec</option>
          <option value="PRIS_PAR_OI">Pris par OI</option>
        </select></div>
      </div>
    </div>
    <div class="files-wrap">
  <div class="dropzones p-2 mt-1 w-100" style="font-size:80%">
    <div class=""style="width:100%" >
      <div  class="d-flex h-100 align-items-center justify-content-between m-1">
        <ng-container *ngFor="let file of files; index as i">
          <div *ngIf="this.files[i][0]!=null" class="d-flex align-items-center"  style="border: dashed var(--c5) 1px; color: #fff;">
            <div class="dropzone_labels p-5 mt-2 mb-2" style="cursor:pointer;background-color: var(--c4);">
              <div class="x-fs-sm" style="text-align: center;">
                Cliquer pour ouvrir le fichier</div>
                <div class="d-flex justify-content-center">
                  <div class="me-3"  style="text-align: center;" (click)="removeFile(i)"><img src = "assets/icon/edit.svg" alt="My Happy SVG"/></div>
                  <div  style="text-align: center;" (click)="viewDoc(binaryDocs[i],this.files[i][0].type)"><img src = "assets/icon/view.svg" alt="My Happy SVG"/></div>
                </div>
            </div>
          </div>
          <ngx-dropzone *ngIf="this.files[i][0]==null"
          class="d-flex h-100 align-items-center m-1"
          [multiple]="false"
          (change)="onSelect($event,viewedFree,i)"f
          style="background-color: transparent;"
        >
          <ngx-dropzone-label>
            <div class="dropzone_labels p-5"   style="cursor:pointer;">
              <div class="x-fs-sm">
                Sélectionnez un fichier</div>
                <img src = "assets/icon/upload_file.svg" alt="My Happy SVG"/>
            </div>
          </ngx-dropzone-label>
        </ngx-dropzone>
        </ng-container>
        <div class="w-25 d-flex ">
          <div class="p-2 w-100 d-flex  align-items-between justify-content-center ">
            <div>
              <div class="mb-1 text-center"><strong>Remarques</strong></div>
              <div>
                <textarea
                rows="4"
                cols="33"
                type="text"
                class="form-control"
                [(ngModel)]="viewedFree.remarques"
              >
              </textarea>
              </div>
            </div>
            </div>
      </div>
      </div>
    </div>
  </div>
  </div>
    <div class="w-100 p-4 pt-1 mb-0 d-flex justify-content-center align-items-center">
      <div  class="d-flex w-25 justify-content-center mb-3" >
        <button  class="col btn-light btn" type="button" mat-dialog-close (click)="onCancelDialog()" >Annuler</button>
        <button [disabled]="isSendingFile"  class="col btn-confirm btn" type="button"(click)="onConfirmDialog(viewedFree)">
          Confirmer
        </button>
        <div *ngIf="isSendingFile" class="spinner-square mt-3 ms-2">
          <div class="square-1 square"></div>
          <div class="square-2 square"></div>
          <div class="square-3 square"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template let-data #displayDocDialog class="display_doc">
  <div class="card_content">
    <img [src]="imgDoc" class="img-fluid" alt="Displayed Image Document">
    </div>
  <div class="card_buttons">
    <div mat-dialog-actions class="cancel_button">
      <div class="text-center x-cl-primary  x-shadow pt-2 pb-2 m-1"  mat-button mat-dialog-close (click)="onCancelDialog()" style="cursor: pointer;">Annuler</div>
    </div>
  </div>
</ng-template>


  <!-- Modal -->
  <div class="modal fade" id="ajouterInterventions" tabindex="-1" aria-labelledby="#ajouterInterventions" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-2 d-flex flex-column justify-content-between align-items-center" style="height: 400px;">
            <div class="d-flex flex-column align-items-center" style="font-family:  Inter-Regular, sans-serif;">
                <div class="pb-3"><h5 class="modal-title" id="exampleModalLabel" ><strong>Ajouter des interventions</strong></h5></div>
                <div class="text-muted w-75 text-center"><span style="font-size:0.9rem">Ajouter les interventions à partir d'un fichier Excel</span></div>
            </div>
            <div class="w-75 mb-3">
                <input #fpsFileUploader (change)="onChange($event)" class="form-control"
                type="file" id="fpsFileUploader">           
            </div>
            <div  class="d-flex w-50 justify-content-center mb-3" >
              <button  class="btn-light btn" type="button"  id="closeAddInterventionsModal" data-bs-dismiss="modal">Annuler</button>
                <button [disabled]="isSendingFile"  class="btn-confirm btn"  type="button" (click)="addInteventionsFromExcelFile()">
                  Confirmer
                </button>
                <div *ngIf="isSendingFile" class="spinner-square mt-3 ms-2">
                  <div class="square-1 square"></div>
                  <div class="square-2 square"></div>
                  <div class="square-3 square"></div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
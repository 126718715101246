import { Component } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation.service';

@Component({
  selector: 'app-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.css']
})
export class ForbiddenPageComponent {
  constructor(private navigationService:NavigationService) { }
  navigateToUserHomePage(){
    this.navigationService.navigateToUserHomePage();
  }

}

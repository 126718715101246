<div class="comparaison_global">
  <div class="comparaison_titles">
    <h1>Comparaisons</h1>
  </div>
  <div class="comparaison_body">
    <form class="comparaison_form">
      <!-- <h4>
        Choisir le mois et l'annnée de la comparaison des interventions désirées
      </h4> -->
      <div class="comparaison_ff">
        <mat-form-field>
          <mat-label>Mois</mat-label>
          <mat-select [(ngModel)]="selectedMonth" name="monthOptions">
            <mat-option
              *ngFor="let month of monthOptions"
              [value]="month.value"
            >
              {{ month.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Année</mat-label>
          <mat-select [(ngModel)]="selectedYear" name="yearOptions">
            <mat-option *ngFor="let year of yearOptions" [value]="year.value">
              {{ year.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="comparaison_button">
      <button
        type="button"
        class="btn btn-primary"
        (click)="loadRealiseesNonFacturees()"
        [ngStyle]="{ 'background-color': isDate ? 'green' : 'Grey' }"
      >
        Rechercher
      </button>
    </div>

    <div class="rnf_fnr_global" *ngIf="isLoadRealiseesNonFacturees">
      <div class="comparaison_sub_titles">
        <h2>Inters Realisees Non Facturees</h2>
      </div>

      <div class="table_global">
        <table
          datatable
          class="table table-bordered table-striped table-hover"
          [dtOptions]="dtOptions"
          *ngIf="RealiseesNonFacturees?.length != 0"
        >
          <thead>
            <tr>
              <th>ID RDV</th>
              <th>Code Tech</th>
              <th>Statut RDV</th>
              <th>Type Tache</th>
              <th>Département</th>
              <th>Date Soumis</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let RealiseesNonFacturee of RealiseesNonFacturees">
              <td>{{ RealiseesNonFacturee.idRdv }}</td>
              <td>{{ RealiseesNonFacturee.techs.codeTech }}</td>
              <td>{{ RealiseesNonFacturee.statutRdv }}</td>
              <td>{{ RealiseesNonFacturee.typeTache }}</td>
              <td>{{ RealiseesNonFacturee.departement }}</td>
              <td>{{ RealiseesNonFacturee.dateSoumis }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="rnf_fnr_global">
      <div class="comparaison_sub_titles" *ngIf="isLoadFactureesNonRealisees">
        <h2>Inters Facturees Non Realisees</h2>
      </div>

      <div class="table_global" *ngIf="isLoadFactureesNonRealisees">
        <table
          datatable
          class="table table-bordered table-striped table-hover"
          [dtOptions]="dtOptions"
          *ngIf="FactureesNonRealisees?.length != 0"
        >
          <thead>
            <tr>
              <th>ID RDV</th>
              <th>Code Tech</th>
              <th>Statut RDV</th>
              <th>Type Tache</th>
              <th>Département</th>
              <th>Date Soumis</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let FactureesNonRealisee of FactureesNonRealisees">
              <td>{{ FactureesNonRealisee.idRdv }}</td>
              <td>{{ FactureesNonRealisee.techs.codeTech }}</td>
              <td>{{ FactureesNonRealisee.statutRdv }}</td>
              <td>{{ FactureesNonRealisee.typeTache }}</td>
              <td>{{ FactureesNonRealisee.departement }}</td>
              <td>{{ FactureesNonRealisee.dateSoumis }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="w-100 h-100" style="position: absolute">
  <div class="sidebar "style="z-index: 99;position: fixed;top:0 ">
    <div  class="w-100 h-100 d-flex  justify-content-between flex-column p-2 " style="font-size: larger;color: rgb(88, 88, 88);">
      <div class="w-100 d-flex flex-column" >
        <div class="mt-4 pointer">        
          <a  class="p-2 d-flex"  [routerLink]="['/dashboard']"  routerLinkActive="active" >
            <i class="fi fi-rr-home"></i>
          <span class="ps-2" style="font-size: 0.9rem; font-weight: 100;">Home</span>
        </a>
        </div>
        <div class="pt-2 pointer ">
          <a class="p-2 d-flex" [routerLink]="['/inters-free']" routerLinkActive="active" > 
            <i class="i fi-rs-hammer" ></i>
            <span class="ps-2"  style="font-size: 0.9rem; font-weight: 100;">Intervention free</span>
        </a>
        </div>
        <div class="pt-2 pointer ">
          <a class="p-2 d-flex" [routerLink]="['/itineraires-free']" routerLinkActive="active" > 
            <i class="fi fi-rs-route" ></i>
            <span class="ps-2"  style="font-size: 0.9rem; font-weight: 100;">Iteneraire free</span>
        </a>
        </div>
        <div class="pt-2 pointer ">
          <a class="p-2 d-flex" [routerLink]="['/techs']" routerLinkActive="active" > 
            <i class="fi-rs-hard-hat" ></i>
            <span class="ps-2"  style="font-size: 0.9rem; font-weight: 100;">Techniciens</span>
        </a>
        </div>
        <div  class="pt-2 pointer">
          <a class="p-2 d-flex" [routerLink]="['/users']" routerLinkActive="active" >
            <i class="fi fi-rs-admin-alt" ></i>
            <span class="ps-2"  style="font-size: 0.9rem; font-weight: 100;">Utilisateurs</span>
          </a>
        </div>
  </div>
  <div  class="w-100 pt-4 pointer" style="position: relative; bottom:6rem;" >
    <div  class="pt-2 pointer">
      <a class="p-2 d-flex" [routerLink]="['/in-development']" routerLinkActive="active" >
        <i class="fi fi-br-life-ring"></i>
        <span class="ps-2"  style="font-size: 0.9rem; font-weight: 100;">Support</span>
      </a>
    </div>
    <div  class="pt-2 pointer">
      <a class="p-2 d-flex" [routerLink]="['/in-development']" routerLinkActive="active" >
        <i class="fi fi-rr-settings"></i>
        <span class="ps-2"  style="font-size: 0.9rem; font-weight: 100;">Paramétres</span>
      </a>
    </div>
    <div  class="pt-2 pointer">
      <a class="p-2 d-flex" (click)="signOut()" >
        <i class="fi fi-br-exit"></i>
        <span class="ps-2"  style="font-size: 0.9rem; font-weight: 100;">Déconnecter</span>
      </a>
    </div>
  </div>
      </div>
        
  </div>
  <div class="w-100 navbar_global d-flex justify-content-between align-items-center" style= "height: 60px;z-index: 99;position: fixed;top:0; background-color:rgb(250, 250, 250)">
    <div class="p-2 logo"style="border-bottom:1px solid #efecec">
      <a  href="/dashboard"
        ><img src="../assets/logo/logo_ca_eitafibre.png" alt=""
      /></a>
    </div>
    <div class="d-flex align-items-center" style="font-size: 80%">
      <strong class="me-3 p-1" style="color:var(--g8);font-size: 90%">
        <i class="fi fi-br-calendar-clock me-2"></i>
        <span>{{currentDateAndTime | date:'MMMM d, y'}}</span>
      </strong>
      <div class="me-3 p-1 pe-2 ps-4" style="background-color: var(--c5);border-radius: 20px;">
        <span class="pe-2" style="color: var(--g8);">{{username}}</span>
        <img src="assets/icon/male-profile.png" width="30px" height="30px">
    </div>
    </div>
  </div>
</div>


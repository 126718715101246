import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private baseUrl = environment.BASE_URL;
  headers = new HttpHeaders().set(
    'Authorization',
    `Bearer ${TokenStorageService.getToken()}`
  );

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService
  ) { }

  getAllUsers(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/allUsers`, {
      headers: this.headers,
    });
  }
  addUser(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/addUser`, user, {
      headers: this.headers,
    });
  }
  deleteUserById(userId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/deleteUser/${userId}`, {
      headers: this.headers,
    });
  }
  getAllRoles(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/allRoles`, {
      headers: this.headers,
    });
  }

  updateUser(user: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/updateUser/${user.id}`, user, {
      headers: this.headers,
    });
  }
  
}

<div *ngIf="!isLoadedPlanning">
  <span class="loader"></span>
</div>

<div class="plan_globale"  *ngIf="isLoadedPlanning">
  <div class="plan_content">
    <div class="user">
      <div class="user_content">
        <div class="user_logo">
          <span>{{ abbreviation }}</span>
        </div>
        <div class="user_info">
          <div class="user_info_content">
            <span class="user_info_1">Bonjour,</span>
            <div class="user_info_2">{{ userEmail }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <div class="title_content">
        <span class="title_1">Bonne intervention !</span>
        <br />
        <span class="title_2">Suivi d'interventions Simplifié</span>
      </div>
    </div>
    <div class="title">
      <div class="title_content">
        <span class="title_1 pe-2">Vous avez encore:</span>
        <span class="title_2">{{planFree.length}} interventions</span>
      </div>
    </div>
    <ng-container *ngIf="planFree.length"> 
      <div class="inters overflow-auto h-75">
        <div class="inters_content" *ngFor="let item of planFree">
          <div class="card mb-2" (click)="viewPlanFree(item)">
            <div class="card-body text-primary">
              <div class="card_ligne">
                <img src="../assets/icon/icon_ref.svg" alt="icon Référence" />
                <span class="card_ligne_txt_1">{{ item.refTicketHlp }} </span>
              </div>
              <div class="card_ligne">
                <img
                  src="../assets/icon/icon_localisation.svg"
                  alt="icon localisation"
                />
                <span class="card_ligne_txt_2">{{ item.adresse }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container  *ngIf="!planFree.length">
      <div class="d-flex w-100 h-75 justify-content-center align-items-center">
        <div class="d-flex flex-column align-items-center">
          <span><img src="assets\icon\no-plan.svg"></span>
          <div class="text-center">
            <div style="font-family: Inter-Regular, sans-serif ;font-size: 80%; color:var(--c4)">Vous n'avez aucune intervention</div>
          </div>  
        </div>
      </div>
    </ng-container>
  </div>
  <div class="logout">
    <div class="logout_content">
      <div class="logout_logo">
      </div>
      <div class="logout_txt">
        <span (click)="logOut()">Déconnexion</span>
      </div>
    </div>
  </div>
</div>

<ng-template #planFreeDetailsDialog>

  <div class="p-2 h-100">
    <div class="card_title">
      <h2 mat-dialog-title>Détails de l'Intervention</h2>
    </div>
  
    <div class="card_content h-75 overflow-auto">
      <div class="card_info_grey">
        <div class="card_info_title">
          <span>Type MalFaçon</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.typeMalfacon }}</span>
        </div>
      </div>
  
      <div class="card_info_white">
        <div class="card_info_title">
          <span>Ref_ticket_hlp</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.refTicketHlp }}</span>
        </div>
      </div>
  
      <div class="card_info_grey">
        <div class="card_info_title">
          <span>Infra</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.infra }}</span>
        </div>
      </div>
  
      <div class="card_info_white">
        <div class="card_info_title">
          <span>Label Court</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.labelCourt }}</span>
        </div>
      </div>
  
      <div class="card_info_grey">
        <div class="card_info_title">
          <span>Département</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.department.code }}</span>
        </div>
      </div>
  
      <div class="card_info_white">
        <div class="card_info_title">
          <span>REF PTO</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.refPto }}</span>
        </div>
      </div>
  
      <div class="card_info_grey">
        <div class="card_info_title">
          <span>REF PBO</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.refPbo }}</span>
        </div>
      </div>
  
      <div class="card_info_white">
        <div class="card_info_title">
          <span>REF PM</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.refPm }}</span>
        </div>
      </div>
  
      <div class="card_info_grey">
        <div class="card_info_title">
          <span>Adresse PTO</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.adressePto }}</span>
        </div>
      </div>
  
      <div class="card_info_white">
        <div class="card_info_title">
          <span>Adresse PBO</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.adressePbo }}</span>
        </div>
      </div>
  
      <div class="card_info_grey">
        <div class="card_info_title">
          <span>Adresse PM</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.adressePm }}</span>
        </div>
      </div>
  
      <div class="card_info_white">
        <div class="card_info_title">
          <span>Adresse</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.adresse }}</span>
        </div>
      </div>
  
      <div class="card_info_grey">
        <div class="card_info_title">
          <span>Statut</span>
        </div>
        <div class="card_info_content">
          <span>{{ viewedPlanFree.fixedStatus }}</span>
        </div>
      </div>
      <div class="card_info_white">
        <div class="card_info_title">
          <span>Détails Demand</span>
        </div>
        <div class="card_info_content">
          <span> {{viewedPlanFree.detailDemande}}</span>
        </div>
      </div>
  
      <div class="card_info_grey">
        <div class="card_info_title">
          <span>Remarques</span>
        </div>
        <div class="card_info_content">
          <!-- <textarea
            class="textarea_grey" style="width: 100%;"
            type="text"
            [(ngModel)]="viewedPlanFree.remarques"
            disabled
          </textarea>
          > -->
          <span> {{viewedPlanFree.remarques}}</span>
        </div>
      </div>
    </div>
    <div class="card_info mt-1">
      <span  style="color: var(--c1) !important;"
        >Avez-vous des commentaires ou des remarques à partager concernant cette
        intervention ?</span
      >
      <textarea
        class="form-control textarea_white mt-1"
        type="text"
        [(ngModel)]="remarques"
        placeholder="Ecrire ici vos remarques"
      >
      </textarea>
    </div>
    <div class="d-flex justify-content-center mt-1">
          <button [disabled]="loading" class="btn  m-1 mb-2" style="background-color: var(--primary);color:white;font-size: 70%;"
            (click)="confirmPlanFreeOk(viewedPlanFree)"
          >
            OK
          </button>
          <button [disabled]="loading"  class="btn" style="background-color: var(--danger);color:white;font-size: 70%;"
            (click)="confirmPlanFreeNok(viewedPlanFree)"
            class="btn  m-1 mb-2"
          >
            Non OK
          </button>
          <button [disabled]="loading"  class="btn m-1 mb-2" style="border:var(--primary);color:var(--primary);font-size: 70%;" mat-dialog-close>
            Annuler
          </button>
          <div *ngIf="loading" class="spinner-square mt-3 ms-2">
            <div class="square-1 square"></div>
            <div class="square-2 square"></div>
            <div class="square-3 square"></div>
          </div>
    </div>
  </div>

</ng-template>

import { Component, OnInit } from '@angular/core';
import { IntersService } from '../core/services/inters.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.css'],
})
export class ComparisonComponent implements OnInit {
  RealiseesNonFacturees: any = [];
  FactureesNonRealisees: any = [];
  dtOptions: any = {};
  allFacturees: any;
  isLoadedRealisees: boolean = false;
  isLoadedFacurees: boolean = false;
  isDate: boolean = false;
  isLoadRealiseesNonFacturees: boolean = false;
  isLoadFactureesNonRealisees: boolean = false;

  // monthSelectedOption: string = '';
  // yearSelectedOption: string = '';

  selectedMonth: string = '';
  selectedYear: string = '';

  monthOptions = [
    { viewValue: 'Janvier', value: 1 },
    { viewValue: 'Février', value: 2 },
    { viewValue: 'Mars', value: 3 },
    { viewValue: 'Avril', value: 4 },
    { viewValue: 'Mai', value: 5 },
    { viewValue: 'Juin', value: 6 },
    { viewValue: 'Juillet', value: 7 },
    { viewValue: 'Aout', value: 8 },
    { viewValue: 'Septembre', value: 9 },
    { viewValue: 'Octobre', value: 10 },
    { viewValue: 'Novembre', value: 11 },
    { viewValue: 'Décembre', value: 12 },
  ];

  yearOptions = [
    { viewValue: '2021', value: 2021 },
    { viewValue: '2022', value: 2022 },
    { viewValue: '2023', value: 2023 },
    { viewValue: '2024', value: 2024 },
    { viewValue: '2025', value: 2025 },
    { viewValue: '2026', value: 2026 },
    { viewValue: '2027', value: 2027 },
    { viewValue: '2028', value: 2028 },
    { viewValue: '2029', value: 2029 },
  ];

  constructor(private intersService: IntersService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  loadRealiseesNonFacturees() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.13.5/i18n/fr-FR.json',
      },
      dom: 'Bfrtip',
      buttons: [
            'copy', 'excel', 'print'
        ]
    };

    if (this.selectedMonth != '' && this.selectedYear != '') {
      const date = {
        month: this.selectedMonth,
        year: this.selectedYear,
      };
      this.isDate = true;
      this.intersService.getRealiseesNonFacturees(date).subscribe(
        (res: any) => {
          this.RealiseesNonFacturees = res.content;
          this.RealiseesNonFacturees.map((item:any) =>{item.dateSoumis = item.dateSoumis.substr(0,10)})
          this.isLoadRealiseesNonFacturees = true;
        },
        (err: any) => {
         this.toastrService.error("Erreur lors de la récupération des données")
          this.isLoadRealiseesNonFacturees = false;
        }
      );

      this.intersService.getFactureesNonRealisees(date).subscribe(
        (res: any) => {
          this.FactureesNonRealisees = res.content;
          this.FactureesNonRealisees.map((item:any) =>{item.dateSoumis = item.dateSoumis.substr(0,10)})
          this.isLoadFactureesNonRealisees = true;
        },
        (err: any) => {
          this.toastrService.error("Erreur lors de la récupération des données")
          this.isLoadFactureesNonRealisees = false;
        }
      );
    }
  }
}

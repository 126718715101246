import { Component } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.css']
})
export class NotFoundPageComponent {
  constructor(private navigationService:NavigationService) { }
  navigateToUserHomePage(){
    this.navigationService.navigateToUserHomePage();
  }
}

<div class="h-100 w-100 d-flex flex-column justify-content-between p-4">
  <div class="p-2 text-center">
    <span><h3>Nouveau Technicien</h3></span>
  </div>
  <div class="text-center p-2" style="font-size: 0.9rem;">Saisir les champs pour ajouter un nouveau technicien</div>
  <mat-dialog-content>
    <form 
      [formGroup]="techForm"
      class="w-100 p-4 pb-0 mb-4"
    >
      <div class="p-1">
            <label for="codeTech" class="form-label" style="color:var(--c3)">Code Technicien</label>
            <input [disabled]="loading"
              class="form-control"
              formControlName="codeTech"
              
              [ngClass]="{
                'is-invalid': f['codeTech'].touched && f['codeTech'].invalid
              }"
            />
              <span class="invalid-feedback" *ngIf="f['codeTech'].hasError('required')">Code Technicien est requis.</span>
      </div>

      <div class="p-1">
            <label for="prenom" class="form-label" style="color:var(--c3)">Prénom</label>
            <input [disabled]="loading"
              class="form-control"
              formControlName="prenom"
              [ngClass]="{
                'is-invalid': f['prenom'].touched && f['prenom'].invalid
              }"
            />
                <span  class="invalid-feedback" *ngIf="f['prenom'].hasError('required')">Prénom est requis.</span>
      </div>

      <div class="p-1">
            <label for="nom" class="form-label" style="color:var(--c3)">Nom </label>
            <input [disabled]="loading"
              class="form-control"
              formControlName="nom"
              [ngClass]="{
                'is-invalid': f['nom'].touched && f['nom'].invalid
              }"
            />
              <span  class="invalid-feedback" *ngIf="f['nom'].hasError('required')">Nom est requis.</span>
      </div>

      <div class="p-1">
            <label for="email" class="form-label" style="color:var(--c3)">E-mail</label>
            <input [disabled]="loading"
              class="form-control"
              id="signInEmail"
              aria-describedby="signInEmail"
              formControlName="email"
              [ngClass]="{
                'is-invalid': f['email'].touched && f['email'].invalid
              }"
            />
              <span class="invalid-feedback" *ngIf="f['email'].hasError('email') || f['email'].hasError('required')"
                >Mettez une adresse E-mail valide.</span>
      </div>
    </form>
    <div class="w-100 d-flex justify-content-center">
      <div class="w-50 d-flex justify-content-center align-items-center">
      <button  id="closeDialogBtn" class="btn-light btn"  type="button" mat-dialog-close (click)="onCancelClick()">Annuler</button>
      <button   [disabled]="loading"   (click)="submitTech()" class="btn-confirm btn">Ajouter</button>
      <div *ngIf="loading" class="spinner-square mt-1 ms-2">
        <div class="square-1 square"></div>
        <div class="square-2 square"></div>
        <div class="square-3 square"></div>
      </div>
      </div>
  </div>
  </mat-dialog-content>
</div>

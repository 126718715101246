import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { AjoutTechsComponent } from '../ajout-techs/ajout-techs.component';
import { ExcelService } from 'src/app/core/services/excel.service';
import { Technician } from 'src/app/core/models/Technician';
import { TechnicianService } from 'src/app/core/services/technician.service';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-liste-techs',
  templateUrl: './liste-techs.component.html',
  styleUrls: ['./liste-techs.component.css'],
})
export class ListeTechsComponent implements OnInit {
  techs: Technician[] = [];
  statusMessage: string = '';
  isLoadedTechs: boolean = false;
  imgActiv: string = '../assets/icon/activer.png';
  imgDeactiv: string = '../assets/icon/desactiver.png';
  imgPath: string = '../assets/icon/activer.png';
  allTechs: Technician[] = [];
  dialogRef: any;
  viewedTech!: Technician;
  initModifTech: boolean = false;
  modifiedTech: boolean = false;
  errorMessage: string = "";
  loading: boolean = false;

  @ViewChild('techDialog') techDialog = {} as TemplateRef<string>;

  constructor(
    private techsService: TechnicianService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private excelService: ExcelService,
    private toastService: ToastrService

  ) { }
  dtOptions: DataTables.Settings = {
    // pageLength: 5,
    paging:false,
    lengthChange: false,
    ordering: false,
    language: {
      processing:     "Traitement en cours...",
      search:         "Rechercher&nbsp;:",
      lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
      info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
      infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
      infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
      infoPostFix:    "",
      loadingRecords: "Chargement en cours...",
      zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
      emptyTable:     "Aucune donnée disponible dans le tableau",
      paginate: {
          first:      "Premier",
          previous:   "Pr&eacute;c&eacute;dent",
          next:       "Suivant",
          last:       "Dernier"
      },
      aria: {
          sortAscending:  ": activer pour trier la colonne par ordre croissant",
          sortDescending: ": activer pour trier la colonne par ordre décroissant"
      }}
  }

  ngOnInit() {
    this.loadTechs();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AjoutTechsComponent, {
      width: '40%',
      height: '70%',
    });
  }

  loadTechs() {
    this.techsService.getAll().subscribe(
      (response: any) => {
        this.allTechs = this.sortAllTechs(response.content, 'codeTech');
        this.allTechs.map((item: any) => {
          item['prenomNom'] = item.prenom + " " + item.nom;
        })
        this.techs = this.allTechs;
        this.isLoadedTechs = true;
      },
      (error: any) => {
        this.toastService.error("Erreur lors de la récupération des techniciens");
      }
    );
  }

  sortAllTechs(list: any[], column: string): any[] {
    let sortedArray = list.sort((a, b) => {
      if (a[column] > b[column]) {
        return 1;
      }
      if (a[column] < b[column]) {
        return -1;
      }
      return 0;
    });
    return sortedArray;
  }

  toggleTechStatus(tech: any) {
    this.techsService.toggleStatus(tech.id, !tech.actif).subscribe(
      (response: any) => {
        this.imgPath = tech.actif ? this.imgDeactiv : this.imgActiv;
        const updatedTechIndex = this.allTechs.findIndex(
          (e: any) => e.id == tech.id
        );
        this.allTechs[updatedTechIndex].actif = !tech.actif;
      },
      (error) => {
        this.imgPath = this.imgDeactiv;
      }
    );
  }

  saveExcel() {
    this.excelService.exportAsExcelFile(this.fixTechsExcel(), 'liste_techs');
  }

  fixTechsExcel(): any {
    let techsPreModif = [...this.techs]
    return techsPreModif.map((newObject: any) => {
      const downloadedTechs = { ...newObject }
      delete downloadedTechs.prenomNom
      if (downloadedTechs.actif == true) {
        downloadedTechs.actif = 'Actif'
      } else {
        downloadedTechs.actif = 'Inactif'
      }
      return downloadedTechs;
    });
  }

  viewTechDetails(tech: any) {
    this.viewedTech = tech;
    this.dialogRef = this.dialog.open(this.techDialog,
      { data: this.viewedTech, height: '60%', width: '40%' });
  }

  modifTech() {
    this.initModifTech = true;
  }

  async saveModifTech(tech: any) {
    this.loading=true;
    const techUpdated = {
      idTech: tech.id,
      prenom: tech.prenomNom,
      nom: tech.nom,
      email: tech.email,
      actif: tech.actif
    };
        if (!this.containNumbers(techUpdated.prenom) && !this.containNumbers(techUpdated.nom)) {
          this.techsService.updateTech(tech).subscribe(
            (data: any) => {
              this.dialogRef.close('Confirm');
              this.modifiedTech = true;
              this.initModifTech = false;
              this.loading=false;
              this.loadTechs();
              this.toastService.success("Modification Informations Technicien avec Succés !");
            },
            (error) => {
              this.toastService.error("Modification Informations Technicien a échouée !");
              this.errorMessage = error.error.message;
              this.loading=false;
              this.modifiedTech = false;
              this.initModifTech = false;
            }
          ); 
    }
  }

  async checkExistEmailTech(specifiedId: any, email: any): Promise<boolean> {
    let isExistEmail = false;
    let techs: any = await this.techsService.getAll().forEach((items: any) => {
      for (let item of items.content) {
        if (item.id != specifiedId && item.email == email) {
          isExistEmail = true;
          break;
        }
      }
    }
    );
    return isExistEmail;
  }

  onCancelTechDialog() {
    this.initModifTech = false
    this.modifiedTech = false;
    this.dialogRef.close();
  }

  refresh(): void {
    window.location.reload();
  }

  containNumbers(string: any) {
    return /\d/.test(string);
  }
}
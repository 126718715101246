import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IntersService } from 'src/app/core/services/inters.service';
import { FormControl } from '@angular/forms';
import { ExcelService } from 'src/app/core/services/excel.service';
import { InterventionFree } from 'src/app/core/models/InterventionFree';
import { LabelLongFreeService } from 'src/app/core/services/label-long-free.service';
import { LabelLongFree } from 'src/app/core/models/LabelLongFree';
import { Department } from 'src/app/core/models/Department';
import { DepartmentService } from 'src/app/core/services/department.service';
import { TechnicianService } from 'src/app/core/services/technician.service';
import { Technician } from 'src/app/core/models/Technician';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { INTER_FREE_STATUS_MAP } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-liste-free',
  templateUrl: './liste-free.component.html',
  styleUrls: ['./liste-free.component.css'],
})
export class ListeFreeComponent implements OnInit {
  allFree: any = [];
  isLoadedFree: boolean = false;
  statusMessage: string = '';
  dialogRef: any;
  viewedFree!: InterventionFree;
  viewedDemandeFree: any;
  initFree: any = [];
  selectedLabelLong: any = [];
  selectedDep: any = [];
  selectedTechs: any = [];
  selectedStatus: any = [];
  labelLongs: string[] = [];
  deps: string[] = [];
  techs: string[] = [];
  status: string[] = []
  toppingsType = new FormControl('');
  toppingsDep = new FormControl('');
  toppingsTech = new FormControl('');
  toppingsStatus = new FormControl('');
  allTechs: Technician[] = [];
  allStatus: any = [];
  updateFree: boolean = false;
  errorMsg: String = "";
  affectedTechs!: Technician;
  isCheckedFree: boolean = false;
  initAffect: boolean = false;
  isAffectedTech: boolean = false;
  initInterStatus: boolean = false;
  sortTypeMalfacon: boolean = false;
  sortRefTicketHlp: boolean = false;
  sortLabelCourt: boolean = false;
  sortLabelLong: boolean = false;
  sortDep: boolean = false;
  typeDoc1: boolean = false;
  typeDoc2: boolean = false;
  typeDoc3: boolean = false;
  file1: File[] = [];
  file2: File[] = [];
  file3: File[] = [];
  files!:File[][]
  imgDoc!: any;
  active = "active_0";
  isCollapsed=true;
  selectedFile:File[]  = []
  docs!:boolean[]
  fileUrl!:any
  fileNames:string[]=["file_0","file_1","file_2"]
  fileUrls:string[]=["","",""]
  fileIndex!:number
  updating=false
  binaryDocs:any=[]
  selectedTechIndex:number=-1
  isSendingFile:boolean=false
  interventionsExcelFile!:File
  filter = new FormControl('', { nonNullable: true });
  data!:any


  @ViewChild('freeDetailsDemandeDialog') freeDetailsDemandeDialog = {} as TemplateRef<string>;
  @ViewChild('freeDetailsDialog') freeDetailsDialog = {} as TemplateRef<string>;
  @ViewChild('displayDocDialog') displayDocDialog = {} as TemplateRef<string>;
  @ViewChild('affectTechDialog') affectTechDialog = {} as TemplateRef<string>;

  LabelLongfrees!:LabelLongFree[]
  interventionFreeDepartments!:Department[]
  actifTechs!: Technician[];
  filteredFree:InterventionFree[]=[]
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private intersService: IntersService,
    private techsService: TechnicianService,
    private excelService: ExcelService,
    private labelLongFreeService:LabelLongFreeService,
    private departmentService:DepartmentService,
    private toastr: ToastrService
  ) { }
  dtOptions: DataTables.Settings = {
    // pageLength: 5,
    paging:false,
    lengthChange: false,
    ordering: false,
    language: {
      processing:     "Traitement en cours...",
      search:         "Rechercher&nbsp;:",
      lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
      info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
      infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
      infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
      infoPostFix:    "",
      loadingRecords: "Chargement en cours...",
      zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
      emptyTable:     "Aucune donnée disponible dans le tableau",
      paginate: {
          first:      "Premier",
          previous:   "Pr&eacute;c&eacute;dent",
          next:       "Suivant",
          last:       "Dernier"
      },
      aria: {
          sortAscending:  ": activer pour trier la colonne par ordre croissant",
          sortDescending: ": activer pour trier la colonne par ordre décroissant"
      }}
  }
  labelLongsDropdownSettings:IDropdownSettings ={
    singleSelection: false,
    idField: 'id',
    textField: 'label',
    selectAllText: 'Tous',
    unSelectAllText: 'Réintialiser',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noDataAvailablePlaceholderText:'Pas de types',
    searchPlaceholderText:'Recherche'
  };
    departmentsDropdownSettings:IDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'code',
      selectAllText: 'Tous',
      unSelectAllText: 'Réintialiser',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText:'Pas du departments',
      searchPlaceholderText:'Recherche'

    };
    techniciansDropdownSettings:IDropdownSettings ={
      singleSelection: false,
      idField: 'id',
      textField: 'nomPrenom', // Corrected concatenation
      selectAllText: 'Tous',
      unSelectAllText: 'Réinitialiser', // Corrected spelling
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Pas du techniens',
      searchPlaceholderText:'Recherche'
  };
    statusDropdownSettings:IDropdownSettings ={
      singleSelection: false,
      idField: 'id',
      textField: 'status', // Corrected concatenation
      selectAllText: 'Tous',
      unSelectAllText: 'Réinitialiser', // Corrected spelling
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Pas du status',
      searchPlaceholderText:'Recherche'
    };
    affecTechnciantDropdownSettings:IDropdownSettings ={
      singleSelection: false,
      idField: 'id',
      textField: 'nomPrenom', // Corrected concatenation
      enableCheckAll:false,
      unSelectAllText: 'Réinitialiser', // Corrected spelling
      itemsShowLimit: 1,
      limitSelection:1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Pas du techniens',
      searchPlaceholderText:'Recherche'
  };
  ngOnInit(): void {
    // this.loadAllTechs();
    this.loadFree();
    this.getAllLabelLongFree();
    this.getInterventionFreeDepartments()
    this.getActifTechs()
    // this.initFilesAndUrls()
    this.docs=[this.typeDoc1,this.typeDoc2,this.typeDoc3]
  }
// for testing 
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  //
  onChange(event: any) {
    this.interventionsExcelFile=event.target.files[0]
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = XLSX.read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const row = XLSX.utils.sheet_to_json(wb.Sheets[sheets[0]]);
          this.data = JSON.stringify(row);
        }
      };
      reader.readAsArrayBuffer(this.interventionsExcelFile);
    }
  addInteventionsFromExcelFile() {
    this.isSendingFile=true
    if (this.interventionsExcelFile) {
      const body = {
        data: this.data,
        filename: this.interventionsExcelFile.name,
      };
      this.intersService.uploadFree(body).subscribe(
        (response: any) => {
          this.isSendingFile=false
          const btn = document.getElementById('closeAddInterventionsModal') ;
          btn?.click();
          window.location.reload();
          this.toastr.success('Fichier ajouté avec succès');
        },
        (error) => {
          const btn = document.getElementById('closeAddInterventionsModal');
          btn?.click();
          this.isSendingFile=false
          this.toastr.error("Erreur lors de l'ajout du fichier");
        }
      );
    }
  }


  setAffectedTechs(tech:Technician,i:number){
    this.affectedTechs=tech
    this.selectedTechIndex=i
    this.isAffectedTech=true
    // this.isAffectedTech = true
  }
  getAllLabelLongFree(){
    this.labelLongFreeService.getAllLabelLongFrees().subscribe((LabelLongfrees:LabelLongFree[])=>{
      this.LabelLongfrees=LabelLongfrees;
    },error=>{
      this.toastr.error('erreur lors du chargement des types de malfacon')
    })
  }
  getInterventionFreeDepartments(){
    this.departmentService.getInterventionFreeDepartments().subscribe((deps:Department[])=>{
      this.interventionFreeDepartments=deps;
    },error=>{
      this.toastr.error('erreur lors du chargement des departements')
    })
  }
  getActifTechs(){
    this.techsService.getActifTechs().subscribe((techs)=>{
      this.actifTechs=techs;
    },error=>{
      this.toastr.error('erreur lors du chargement des techniciens actifs')
    })
  }

  statusFToB(status: any): any {
    let filterListStatus: any = [];
    const listStatusF = ["En attente", "Réussi", "Échec", "Pris par OI"];
    const listStatusB = ["INIT_INTER", "REALISE_OK", "REALISE_NOK", "PRIS_PAR_OI"];
    for (let stat of status) {
      filterListStatus.push(listStatusB[listStatusF.indexOf(stat)]);
    }
    return filterListStatus;
  }

  getInterventionFreeStatus(){
    this.status= Array.from(new Set(this.allFree.map((free:InterventionFree)=>INTER_FREE_STATUS_MAP[free.status])))

  }
  setFilesAndUrls(){
    if(this.files[0][0]) this.fileUrls[0]= this.getFileUrl(this.files[0][0])
    if(this.files[1][0]) this.fileUrls[1]= this.getFileUrl(this.files[1][0])
    if(this.files[2][0]) this.fileUrls[2]= this.getFileUrl(this.files[2][0])
  }
  setSelectedFile(i:number){
    this.selectedFile=this.files[i]
    this.fileIndex=i
    const embed = document.createElement('embed');
      embed.setAttribute('class', 'w-100');
      embed.setAttribute('src', this.fileUrls[i]);
      embed.setAttribute('height', '350');
      embed.setAttribute('type', this.files[i][0].type); // Set the appropriate MIME type
      const container = document.getElementById('file_'+i);
      if(container){
        container.innerHTML = '';
        container.appendChild(embed);
        this.isCollapsed=true
      }
  }
  onSelect(event: any,free:InterventionFree,i:number) {
      this.files[i].splice(0, 1);
      this.files[i] = event.addedFiles
      this.selectedFile= this.files[i]
      const file = this.files[i][0]; // Accessing the first file
      this.fileUrls[i] = this.getFileUrl(this.files[i][0]);
      free['docs']=[]
     if(this.files[0][0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
          const fileContent = reader.result as string;
          free.doc1 = btoa(fileContent);
          free.typeDoc1=this.files[0][0].type
          free.docs.push(free['doc1'])
    }
    reader.readAsBinaryString(this.files[0][0]);
  }
    if(this.files[1][0]){ 
      const reader = new FileReader();
      reader.onloadend = () => {
          const fileContent = reader.result as string;
          free.doc2 = btoa(fileContent);
          free.typeDoc2=this.files[1][0].type
          free.docs.push(free['doc2'])
    }
    reader.readAsBinaryString(this.files[1][0]);
    }
    if(this.files[2][0]){
      const reader = new FileReader();
      reader.onloadend = () => {
          const fileContent = reader.result as string;
          free.doc3 = btoa(fileContent);
          free.typeDoc3=this.files[2][0].type
          free.docs.push(free['doc3'])
    }
    reader.readAsBinaryString(this.files[2][0]);
    }
     
  }


  onConfirmDialog(free: InterventionFree) {
       this.intersService.updateFree(free).subscribe((response: any) => {
      console.log(free)
      this.updateFree = true;
      this.updating=false
      this.dialogRef.close('Confirm');
      this.toastr.success('Mise à jour réussie');
    },
      (error) => {
        this.errorMsg = error.error.message;
        this.updateFree = false;
        this.updating=false
        this.toastr.success('Échec de la mise à jour. Veuillez vérifier les données et réessayer.');
      })
  }
  createEmbedElement(file:File,fileUrl:string,containerId:number){
    const embed = document.createElement('embed');
    embed.setAttribute('class', 'w-100');
    embed.setAttribute('src', fileUrl);
    embed.setAttribute('height', '400');
    embed.setAttribute('type', file.type); // Set the appropriate MIME type
    const container = document.getElementById('file_'+containerId);
    if(container){
      container.innerHTML = '';
      container.appendChild(embed);
      this.isCollapsed=true
    }
  }
  getFileUrl(file: Blob): string {
    return URL.createObjectURL(file);
  }
  loadFree() {
    this.intersService.getAllFree().subscribe(
      (response: any) => {
        this.allFree = response.content;
        if(this.allFree){
          this.getInterventionFreeStatus()
          this.allFree.map((free:InterventionFree)=>{
            free.docs=this.setDocs(free.doc1,free.doc2,free.doc3)
          })
        }
        this.isLoadedFree = true;
      },
      (_error: any) => {
        this.isLoadedFree = true;
        this.toastr.error('Erreur lors du chargement des interventions');
      }
    );
  }

  setDocs(doc1: any, doc2: any, doc3: any): any {
    let docs: any = []
    if(doc1) docs.push(doc1)
    if(doc2) docs.push(doc2)
    if(doc3) docs.push(doc3)
    return docs
  }
  filterByStatus(statusList:string[],freeList:InterventionFree[]):InterventionFree[]{
    if(!statusList.length){
      return freeList;
    }
    return freeList.filter(free =>{ 
      if(free.status) return statusList.includes(INTER_FREE_STATUS_MAP[free.status])
      return false
    });
  }
  filterByTechId(techsIdList:Technician[],freeList:InterventionFree[]):InterventionFree[]{
    if(!techsIdList.length){
      return freeList;
    }
    return freeList.filter((free)=> {
      if(free?.techs) return techsIdList.map(tech=>tech.id).includes(free?.techs.id)
      else return false
    })
  }
  filterByDepartmentId(departmentsIdList:Department[],freeList:InterventionFree[]):InterventionFree[]{
    if(!departmentsIdList.length){
      return freeList;
    }
    return freeList.filter((free)=>  {
      if(free.department) return departmentsIdList.map(dep=>dep.id).includes(free.department.id)
      return false
    })
  }
  filterByLabelLongFreeId(labelLongFreeIdList:LabelLongFree[],freeList:InterventionFree[]):InterventionFree[]{
    if(!labelLongFreeIdList.length){
      return freeList;
    }
    return freeList.filter((free)=> {
      if(free.labelLongFree) return labelLongFreeIdList.map(label=>label.id).includes(free.labelLongFree.id )
      return false
    })
  }
  filterFree(){
    this.filteredFree = this.filterByLabelLongFreeId(this.selectedLabelLong,this.allFree)
    this.filteredFree = this.filterByDepartmentId(this.selectedDep,this.filteredFree)
    this.filteredFree = this.filterByTechId(this.selectedTechs,this.filteredFree)
    this.filteredFree = this.filterByStatus(this.selectedStatus,this.filteredFree)
    return this.filteredFree;
  }

  resetFree() {
    this.selectedLabelLong = [];
    this.selectedDep = [];
    this.selectedTechs = [];
    this.selectedStatus = [];
    this.filteredFree = this.allFree;
    this.initAffect = false;
    this.isCheckedFree = false;
    this.isAffectedTech = false;
    this.filteredFree.forEach((item: any) => item.checked = false);
    this.filteredFree.sort((a: any, b: any) => Number(a.departement) - Number(b.departement));
  }

  selectAllFree(event: any) {
    const checked = event.target.checked;
    if (checked == true) {
      this.isCheckedFree = true;
    } else {
      this.initAffect = false;
      this.isCheckedFree = false;
      this.isAffectedTech = false;
    }
    this.filteredFree.forEach((item: any) => item.checked = checked);
  }

  isAllCheckBoxChecked() {
    return this.filteredFree.every((p: any) => p.checked);
  }

  selectFree(event: any, free: any) {
    const checked = event.target.checked;
    let nbChecked = 0;
    if (checked == true) {
      this.isCheckedFree = true;
    } else {
      for (let free of this.filteredFree) {
        if (free.checked && this.initInterStatus == true) {
          nbChecked++
          break
        }
      }
      if (nbChecked == 0) {
        this.initAffect = false;
        this.isCheckedFree = false;
        this.isAffectedTech = false;
      }
    }
    free.checked = checked;
  }

  affecterFree() {
    this.initAffect = true;
    this.affectedTechs =this.getChekedFrees()[0].techs;
  }
  getChekedFrees():InterventionFree[]{
    return this.allFree.filter((free:InterventionFree)=>free.checked)
  }

  confirmAffectFree() {
    let checkedFree = this.getChekedFrees();
    this.intersService.affectTechsToFree(checkedFree,this.affectedTechs.id).subscribe((res: any) => {
      this.dialogRef.close('Confirm');
      this.filterFree();
      this.toastr.success('Affectation intervention Free a été faite avec succés');
      this.cancelAffectFree();
    }, (err: any) => {
      this.toastr.error('Erreur lors de l\'affectation des interventions');
    }
    )
  }

  affect(e: any) {
    if (e != null || e != undefined) {
      this.isAffectedTech = true
    }
  }

  cancelAffectFree() {
    this.initAffect = false;
    this.isCheckedFree = false;
    this.isAffectedTech = false;
    this.selectedTechIndex=-1
    this.filteredFree.forEach((item: any) => item.checked = false);
  }

  saveExcel() {
    this.excelService.exportAsExcelFile(this.fixFreeExcel(), 'liste_free');
  }

  fixFreeExcel(): any {
    let freePreModif = [...this.filteredFree]
    return freePreModif.map((newObject: any) => {
      const downloadedfree = { ...newObject }
      delete downloadedfree.techs;
      delete downloadedfree.status;
      delete downloadedfree.doc;
      delete downloadedfree.doc1;
      delete downloadedfree.doc2;
      delete downloadedfree.doc3;

      return downloadedfree;
    });
  }

  openListTechs(){
    this.dialogRef = this.dialog.open(this.affectTechDialog,
      { data: this.actifTechs, height: '75%', width: '55%' });
  }

  viewFree(free: any) {
    this.active = "active_0";
    this.fileIndex=0
    this.viewedFree = free;
    this.binaryDocs=[free.doc1,free.doc2,free.doc3]
    this.files=[[],[],[]]
    if(free['doc1']){
      let blob =this.base64ToBlob(free['doc1'],free['typeDoc1']);
      this.files[0]= [new File([blob], "file_name.pdf", {lastModified: 1534584790000,type: blob.type})]
    }
    if(free['doc2']){
      let blob =this.base64ToBlob(free['doc2'],free['typeDoc2']);
      this.files[1]= [new File([blob], "file_name.pdf", {lastModified: 1534584790000,type: blob.type})]
    }
    if(free['doc3']){
      let blob =this.base64ToBlob(free['doc3'],free['typeDoc3']);
      this.files[2]= [new File([blob], "doc.pdf", {lastModified: 1534584790000,type: blob.type})]
    }
    this.setFilesAndUrls();
    setTimeout(()=>{
      this.fileIndex=0
      this.selectedFile=this.files[ this.fileIndex]
      this.createEmbedElement( this.selectedFile[0],this.fileUrls[this.fileIndex],this.fileIndex)
    },500)
    this.dialogRef = this.dialog.open(this.freeDetailsDialog,
      { data: this.viewedFree, height: '85%', width: '65%' });
      
    this.dialogRef.afterClosed().subscribe((result: any) => {
    });
    
  }

  fixTypeDoc(index: number, file: File, free: any) {
    let type !: String;
    if (file != null) {
      type = file['type']
    } else if (free[`typeDoc${index}`] != null && file == null) {
      type = free[`typeDoc${index}`]
    } else {
      type = ''
    }
    return type
  }

  nomPrenomToIdTech(nomPrenom: any): any {
    let idTech: any;
    if (typeof (nomPrenom) === 'string') {
      for (let t of this.allTechs) {
        if (t.nomPrenom.replace(/ /g, '') === nomPrenom.replace(/ /g, '')) {
          idTech = t.id;
        }
      }
    }
    return idTech;
  }

  onCancelDialog() {
    this.fileIndex=0
  }



  viewDoc(doc: any,type:any) {
    if (type.includes('application/pdf') || type.includes('image/')) {
      const blob = this.base64ToBlob(doc, type);
      let pdfURL = URL.createObjectURL(blob);
      window.open(pdfURL);
    }
    else if (type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      const filename = 'doc.xlsx';
      const blob = this.base64ToBlob(doc, type);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } else {
      this.toastr.error('Type de fichier non supporté');
    }
  }
  
  blobToBase64(file:File):Promise<string>{
      return new Promise<string> ((resolve,reject)=> {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => resolve(reader.result?.toString() || '');
           reader.onerror = error => reject(error);
       })
    }

  base64ToBlob(base64Data: string, contentType: string): Blob {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Uint8Array(Array.from(byteCharacters, char => char.charCodeAt(0)));
    return new Blob([byteNumbers], { type: contentType });
  }

  getTypeFile(doc: any, free: any): any {
    let typeFile !: String;

    for (let [key, value] of Object.entries(free)) {
      if (value == doc) {
        let updatedKey = key.toString()[0].toUpperCase() + key.toString().substring(1)
        typeFile = free[`type${updatedKey}`]
      }
    }
    return typeFile
  }

  onMarketChange(event: any) {
  }
  removeFile(i:number){
    this.files[i]=[]
  }
}
<app-main-wrapper>
   <div class="w-100  mb-3 pb-4 pt-5" style="font-size: 0.8rem;" >
    <div class=" w-100 mb-2 ps-0 p-2 d-flex justify-content-between">
      <span style="color:var(--g1)"><h5>Techniciens</h5></span>
      <button  [disabled]="!isLoadedTechs" class="btn-primary  btn d-flex " (click)="openDialog()"  style="font-size: 0.8rem;"><i class="fi fi-rs-plus  pe-1 pt-1"></i><span>Nouveau Technicien</span></button>
    </div>
    <div class="card">
      <div class="p-2  d-flex justify-content-end w-100">
          <button [disabled]="!isLoadedTechs" class="btn-secondary btn" (click)="saveExcel()"  style="font-size: 0.8rem;"><i class="fi fi-rs-file-excel pe-2"></i>Excel</button>
      </div>
    </div>
   </div>
<div *ngIf="!isLoadedTechs">
  <span class="loader"></span>
</div>
  <div class="table_global" *ngIf="isLoadedTechs ">
    <div    class="card p-4 table-responsive" style="font-size: 0.8rem;">
      <table *ngIf="techs" [dtOptions]="dtOptions" class="table table-striped table-hover" datatable>
        <thead>
          <tr>
            <th>Order</th>
            <th scope="col">Code Tech</th>
            <th scope="col">E-mail</th>
            <th scope="col">Prénom & Nom</th>
            <th scope="col">details</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tech of allTechs; let i = index">
            <td>
              <ng-container>{{ i + 1 }}</ng-container>
            </td>
            <td>{{ tech.codeTech }}</td>
            <td>{{ tech.email }}</td>
            <td>{{tech.prenom }} {{tech.nom}}</td>
            <td (click)="viewTechDetails(tech)" class="pointer" style="color:var(--c3)">
              <i class="fi fi-rr-edit"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-main-wrapper>

<ng-template let-data #techDialog>
  <div class="h-100 w-100 d-flex flex-column justify-content-between p-4" >
    <div class="p-2 text-center">
      <span  style="font-size: 0.8rem;"><h3>Modifier technicien</h3></span>
    </div>
    <div class="text-center p-2" style="font-size: medium;font-size: 0.8rem;">Saisir les champs pour modifier le technicien</div>
    <mat-dialog-content>
      <div 
        class="w-100 p-4 pb-0 mb-4"
      >
        <div class="p-1">
              <label for="codeTech" class="form-label" style="color:var(--c3); font-size: 0.8rem;">Code Technicien</label>
              <input disabled
              style="font-size: 0.8rem;"
                class="form-control"
                [(ngModel)]="viewedTech.codeTech"
              />
        </div>
  
        <div class="p-1">
              <label for="prenom" class="form-label" style="color:var(--c3); font-size: 0.8rem;">Prénom</label>
              <input [disabled]="loading"
              style="font-size: 0.8rem;"
                class="form-control"
                [(ngModel)]="viewedTech.prenom"
              />
        </div>
  
        <div class="p-1">
              <label for="nom" class="form-label" style="color:var(--c3); font-size: 0.8rem;">Nom </label>
              <input [disabled]="loading"
              style="font-size: 0.8rem;"
                class="form-control"
                [(ngModel)]="viewedTech.nom"
              />
        </div>
  
        <div class="p-1">
              <label for="email" class="form-label" style="color:var(--c3); font-size: 0.8rem;">E-mail</label>
              <input [disabled]="loading"
                class="form-control"
                style="font-size: 0.8rem;"
                id="signInEmail"
                aria-describedby="signInEmail"
                [(ngModel)]="viewedTech.email"
                disabled
              />
        </div>
        <div class="form-switch pt-2 pb-2 float-end d-flex">
          <input class="form-check-input "           [(ngModel)]="viewedTech.actif" type="checkbox"  id="actif">
          <label class="form-check-label" for="actif">
            <span class="ps-3" *ngIf="viewedTech.actif" style="font-size: 0.8rem;">Technicien activé</span>
            <span class="ps-3" *ngIf="!viewedTech.actif" style="font-size: 0.8rem;">Technicien désactiver</span>
          </label>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <div class="w-50 d-flex justify-content-center align-items-center">
          <button id="closeDialogBtn"  class="btn-light btn"  type="button" mat-dialog-close (click)="onCancelTechDialog()">Annuler</button>
          <button [disabled]="loading"  class="btn-confirm btn"  (click)="saveModifTech(viewedTech)" >Confirmer</button>
          <div *ngIf="loading" class="spinner-square mt-1 ms-2">
            <div class="square-1 square"></div>
            <div class="square-2 square"></div>
            <div class="square-3 square"></div>
          </div>
      </div>
      </div>
     
    </mat-dialog-content>
  </div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {

  resetForm!: FormGroup;
  resetpassword = false;
  statusMessage: string = '';
  errorMessage: any;
  resetToken!: string;
  fieldTextTypePassword: boolean = false;
  fieldTextTypeConfirmPassword: boolean = false;
  token: string | undefined;
  siteKey = environment.RECAPTCHA.SITE_KEY;
  captchaValid: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: [''],
    }, { validators: this.passwordMatchValidator });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.resetToken = params['token'];
    });
  }

  resolved(captchaResponse: string): void {
    this.captchaValid = true;
  }

  onError(errorDetails: any): void {
    this.captchaValid = false;
  }

  get f() {
    return this.resetForm.controls;
  }

  resetPassword() {
    const resetRequest = {
      resetToken: this.resetToken,
      newPassword: this.resetForm.value.confirmPassword,
    };

    this.authService.resetPassword(resetRequest).subscribe(
      (res: any) => {
       this.toastrService.success("Mot de passe réinitialisé avec succès")
        this.resetpassword;
        this.router.navigate(['/login']);
      },
      (error: any) => {
        !this.resetpassword;
        this.toastrService.error("Erreur lors de la réinitialisation du mot de passe")
      }
    );
  }

  toggleFieldTextTypePassword() {
    this.fieldTextTypePassword = !this.fieldTextTypePassword;
  }

  toggleFieldTextTypeConfirmPassword() {
    this.fieldTextTypeConfirmPassword = !this.fieldTextTypeConfirmPassword;
  }

  passwordMatchValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    const passwordControl = control.get('password');
    const confirmPasswordControl = control.get('confirmPassword');
    if (passwordControl?.value !== confirmPasswordControl?.value) {
      confirmPasswordControl?.setErrors({ mismatch: true });
      return { mismatch: true };
    } else {
      confirmPasswordControl?.setErrors(null);
      return null;
    }
  };
}
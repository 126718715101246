import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AjoutRealiseesComponent } from '../ajout-realisees/ajout-realisees.component';
import { IntersService } from 'src/app/core/services/inters.service';
import { ExcelService } from 'src/app/core/services/excel.service';

@Component({
  selector: 'app-liste-realisees',
  templateUrl: './liste-realisees.component.html',
  styleUrls: ['./liste-realisees.component.css'],
})
export class ListeRealiseesComponent implements OnInit {
  statusMessage: string = '';
  allRealisees: any = [];
  realisees: any = [];
  isLoadedRealisees: boolean = false;
  dialogRef: any;
  viewedRealisee: any;

  @ViewChild('realiseeDialog') realiseeDialog = {} as TemplateRef<string>;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private intersService: IntersService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    this.loadRealisees();
  }

  openSnackBarSuccess(message: string) {
    this.snackBar.open(message, '', {
      duration: 3000, panelClass: ['green-snackbar'],
    });
  }

  openSnackBarFailure(message: string) {
    this.snackBar.open(message, '', {
      duration: 3000, panelClass: ['red-snackbar'],
    });
  }

  openDialogRealisees(): void {
    const dialogRef = this.dialog.open(AjoutRealiseesComponent, {
      width: '45%',
      height: '35%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'Confirm') {
        (this.statusMessage = 'Interventions Réalisées ajoutés Success!'),
          this.openSnackBarSuccess(this.statusMessage);
        this.loadRealisees();
      } else if (result == 'Error') {
        (this.statusMessage = 'Interventions Réalisées non ajoutés Fail!'),
          this.openSnackBarFailure(this.statusMessage);
      } else {
        this.loadRealisees();
      }
    });
  }

  loadRealisees() {
    this.intersService.getAllRealisees().subscribe(
      (response: any) => {
        this.allRealisees = response.content;
        this.realisees = this.allRealisees;
        this.isLoadedRealisees = true;
      },
      (error: any) => {
        (this.statusMessage = 'Chargement des interventions Réalisées a échouée!'),
          this.openSnackBarFailure(this.statusMessage);
        this.isLoadedRealisees = false;
      }
    );
  }

  sortAllRealisees(list: any[], column: string): any[] {
    let sortedArray = list.sort((a, b) => {
      if (a[column] > b[column]) {
        return 1;
      }
      if (a[column] < b[column]) {
        return -1;
      }
      return 0;
    });
    return sortedArray;
  }

  onSearchKeyUp(event: any): any {
    let texte = (event.target as HTMLInputElement).value;
    if (texte != "") {
      this.searchRealiseesByString(texte);
    } else {
      this.realisees = this.allRealisees;
    }
  }

  search(string: string) {
    let results: any = [];
    this.realisees = this.allRealisees;
    for (var i = 0; i < this.realisees.length; i++) {
      for (var key in this.realisees[i]) {
        if (typeof this.realisees[i][key] === 'object') {
          if (this.realisees[i][key] != null)
            for (let subkey in this.realisees[i][key]) {
              if (typeof this.realisees[i][key][subkey] === 'string' && this.realisees[i][key][subkey].toLowerCase().includes(string.toLowerCase())) {
                results.push(this.realisees[i]);
                break;
              }
            }
        }
        if (typeof this.realisees[i][key] === 'string' && this.realisees[i][key].toLowerCase().includes(string.toLowerCase())) {
          results.push(this.realisees[i]);
          break;
        }
      }
    }
    return results;
  }

  searchRealiseesByString(string: string): any {
    this.realisees = this.search(string);
    if (this.realisees.length == 0) {
      this.openSnackBarFailure('Pas des utilisateurs avec les informations cherchées !');
    }
  }

  saveExcel() {
    this.excelService.exportAsExcelFile(this.fixRealiseesExcel(), 'liste_realisees');
  }

  fixRealiseesExcel() {
    let realiseesPreModif = [...this.realisees]
    return realiseesPreModif.map((newObject: any) => {
      const downloadedRealisee = { ...newObject }
      if (downloadedRealisee.techs != null) {
        downloadedRealisee.techs = downloadedRealisee.techs.codeTech
      }
      return downloadedRealisee;
    });
  }

  viewRealiseesDetails(Realisee: any) {
    this.viewedRealisee = Realisee;
    this.dialogRef = this.dialog.open(this.realiseeDialog,
      { data: this.viewedRealisee, height: '45%', width: '35%' });
  }

  onCancelTechDialog() {
    this.dialogRef.close();
  }
}
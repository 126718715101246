<div class="login_global">
  <div class="login_content">
    <div class="login_col1">
      <div class="login_col1_global">
        <div class="login_comp_type1">
          <a href="/"
            ><img src="../assets/logo/logo_ca_eitafibre.png" alt="login logo"
          /></a>
        </div>

        <div class="login_comp_type1">
          <div class="login_comp_type2">
            <div class="login_comp_type1">
              <h1>Réinitialiser votre de mot de passe</h1>
            </div>
            <div class="login_comp_type1">
              <h4>
                Merci de saisir votre nouveau mot de passe et le confirmer.
              </h4>
            </div>
          </div>
        </div>

        <div class="login_comp_type1">
          <form
            [formGroup]="resetForm"
            (ngSubmit)="resetPassword()"
            class="has-validation"
          >
            <div class="form-group">
              <label for="password" class="form-label required"
                >Nouveau mot de passe</label
              >
              <div class="input-group">
                <input
                  [type]="fieldTextTypePassword ? 'text' : 'password'"
                  class="form-control mb-0"
                  id="resetPasswordPassword"
                  aria-describedby="resetPasswordPassword"
                  placeholder="Entrez votre nouveau mot de passe"
                  formControlName="password"
                  [ngClass]="{
                    'is-invalid': f['password'].touched && f['password'].invalid
                  }"
                />
                <span
                  class="input-group-text"
                  (click)="toggleFieldTextTypePassword()"
                >
                  <img
                    *ngIf="
                      f['password'].value.length > 0 && !fieldTextTypePassword
                    "
                    src="../assets/icon/icon_eye.svg"
                    alt="icon_eye"
                  />
                  <img
                    *ngIf="
                      f['password'].value.length > 0 && fieldTextTypePassword
                    "
                    src="../assets/icon/icon_eye_slash.svg"
                    alt="icon_eye_slash"
                  />
                </span>
                <div
                  class="invalid-feedback"
                  *ngIf="f['password'].touched && f['password'].invalid"
                >
                  <strong *ngIf="f['password'].hasError('required')">
                    * Le mot de passe est requis.
                  </strong>
                  <strong *ngIf="f['password'].hasError('minlength')">
                    * Le mot de passe doit contenir au moins 6 caractères.
                  </strong>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="confirmPassword" class="form-label required"
                >Confirmez le nouveau mot de passe</label
              >
              <div class="input-group">
                <input
                  [type]="fieldTextTypeConfirmPassword ? 'text' : 'password'"
                  class="form-control"
                  id="resetPasswordConfirmPassword"
                  aria-describedby="resetPasswordConfirmPassword"
                  placeholder="Confirmez votre nouveau mot de passe"
                  formControlName="confirmPassword"
                  [ngClass]="{
                    'is-invalid':
                      f['confirmPassword'].touched &&
                      f['confirmPassword'].invalid
                  }"
                />
                <span
                  class="input-group-text"
                  (click)="toggleFieldTextTypeConfirmPassword()"
                >
                  <img
                    *ngIf="
                      f['confirmPassword'].value.length > 0 &&
                      !fieldTextTypeConfirmPassword
                    "
                    src="../assets/icon/icon_eye.svg"
                    alt="icon_eye"
                  />
                  <img
                    *ngIf="
                      f['confirmPassword'].value.length > 0 &&
                      fieldTextTypeConfirmPassword
                    "
                    src="../assets/icon/icon_eye_slash.svg"
                    alt="icon_eye_slash"
                  />
                </span>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    f['confirmPassword'].touched && f['confirmPassword'].invalid
                  "
                >
                  <strong *ngIf="f['confirmPassword'].hasError('required')">
                    * La confirmation du mot de passe est requise.
                  </strong>
                  <strong
                    *ngIf="
                      resetForm.hasError('mismatch') &&
                      f['confirmPassword'].value.length > 0
                    "
                  >
                    * Les mots de passe ne correspondent pas.
                  </strong>
                </div>
              </div>
              <div
                [ngClass]="{
                  captcha_invalid: !resetForm.valid
                }"
              >
                <div class="captcha">
                  <re-captcha
                    (resolved)="resolved($event)"
                    (errored)="onError($event)"
                    errorMode="handled"
                    [siteKey]="siteKey"
                  ></re-captcha>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="login_button">
                <button
                  class="btn btn-primary btn-block"
                  [disabled]="!captchaValid"
                >
                  Réinitialiser
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="login_col2">
      <img
        src="../assets/background/login_background.jpg"
        alt="Login background"
      />
    </div>
  </div>
</div>

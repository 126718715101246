<div class="w-100 h-100 d-flex justify-content-center align-items-center gradient-background" style="font-family:Inter-Regular">
  <div class="h-75 d-flex flex-column justify-content-start" style="width:550px; background: #fff;border-radius: 5px;" >
    <div class="w-100 d-flex justify-content-center p-3">
      <img width="60px" height="60px" src="../assets/logo/logo_ca_eitafibre.png" alt="login logo" />
    </div>
    <div class="p-3 text-center" style="color:var(--c3);">
      <strong>Bienvenue de nouveau !</strong>
    </div>
    <div class="p-3 text-center" style="color:var(--c3);">
      <span>Connexion</span>
    </div>
    <form class="w-100 h-50 mt-5 d-flex align-items-center justify-content-between flex-column"
    [formGroup]="loginForm"
    (ngSubmit)="onSubmit()"
    >
      <div class="d-flex d-flex flex-column w-75 input-box">
        <!-- <label for="email">Email</label> -->
        <input type="text" id="email" formControlName="email" placeholder="Email"  required/>
      </div>
      <span class="w-75" *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].touched" style="color:rgb(255, 97, 97);font-size:small; position: relative; left:0;">* e-mail est obligatoire</span>
      <div class="d-flex d-flex flex-row w-75 align-items-center input-box">
        <!-- <label for="email">Email</label> -->
        <input class="w-100" [type]="showPassword ? 'text' : 'password'" id="password" formControlName="password" placeholder="Mot de passe"  required
        required/>
        <div class="pointer" style="color: #d0d0d0" (click)="togglePassword()">
          <i *ngIf="showPassword" class="fi fi-rs-crossed-eye pe-2 pt-1"></i>
          <i *ngIf="!showPassword" class="fi fi-rs-eye pe-2 pt-1"></i>
        </div>
      </div>
      <span class="w-75" *ngIf="loginForm.controls['password'].invalid && loginForm.controls['email'].touched && !loginForm.controls['email'].invalid" style="color:rgb(255, 97, 97);font-size:small; position: relative; left:0;">*Password est obligatoire</span>
      <div class="w-75">
        <div [ngClass]="{ captcha_invalid: !loginForm.valid}">
          <re-captcha class="captcha"
            (resolved)="resolved($event)"
            (errored)="onError($event)"
            errorMode="handled"
              [siteKey]="siteKey"
          ></re-captcha>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <button class="w-75 con pointer" [disabled]="!captchaValid">
           <small>Connexion</small> 
        </button>
      </div>
    </form>
    <div class="w-100 h-25 d-flex align-items-center flex-column justify-content-around">
      <div class="w-75 d-flex justify-content-center">
        <small  class="pointer" routerLink="/forgotPassword" style="color:var(--c3)">Mot de passe oublié!<span class="ps-3">&#8594;</span></small>
      </div>
    </div>
  </div>
</div>

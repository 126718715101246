// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  BASE_URL : 'https://ca.eitafibre.fr/api',

  // BASE_URL : 'https://ca.eitafibre.fr/api',

  // BASE_URL: 'http://localhost:8081/api',

  RECAPTCHA: {
    SITE_KEY: '6Leo_3IpAAAAABvceWrszR0L2g_t7VDjgmL21cIx',
    SECRET_KEY: '6Leo_3IpAAAAAI1oliDWKDWqBsvNLCzxOvnosEhg'
  },

  GEOAPIFY_API: {
    KEY: 'a0ee1bfe2fec4f708346ab94ff90fcbe'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

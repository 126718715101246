export const INTER_FREE_STATUS_MAP:{[key:string]:string} = {
    "INIT_INTER":"En attente", 
    "REALISE_OK":"Réussi", 
    "REALISE_NOK":"Échec", 
    "PRIS_PAR_OI":"Pris par OI"
  }

export const USER_STATUS_MAP:{[key:string]:string} = {
  true: "active",
  false:"inactive",
}
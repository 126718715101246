import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Department } from 'src/app/core/models/Department';
import { InterventionFree } from 'src/app/core/models/InterventionFree';
import { LabelLongFree } from 'src/app/core/models/LabelLongFree';
import { DepartmentService } from 'src/app/core/services/department.service';
import { LabelLongFreeService } from 'src/app/core/services/label-long-free.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { IntersService } from 'src/app/core/services/inters.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import * as L from 'leaflet';

@Component({
  selector: 'app-iteneraire-free',
  templateUrl: './iteneraire-free.component.html',
  styleUrls: ['./iteneraire-free.component.css']
})
export class IteneraireFreeComponent implements OnInit,AfterViewInit {
  locationForm!: FormGroup;
  depList: string[] = [];
  typeList: string[] = [];
  position: string = "";
  isLoadedPage: boolean = false;
  isLoadingItin: boolean = false;
  selectedLabelLong: LabelLongFree[] = [];
  selectedDep: Department[] = [];
  selectedTypes:string[]=[]
  labelLongs: string[] = [];
  interventionFreeDepartments!: Department[];
  labelLongfrees!:LabelLongFree[];
  toppingsType = new FormControl('');
  toppingsDep = new FormControl('');
  verifiedFree: any = [];
  nonVerifiedFree: any = []
  itineraires: InterventionFree[] = [];

  dtOptions: DataTables.Settings = {
    // pageLength: 5,
    paging:false,
    lengthChange: false,
    ordering: false,
    language: {
      processing:     "Traitement en cours...",
      search:         "Rechercher&nbsp;:",
      lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
      info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
      infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
      infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
      infoPostFix:    "",
      loadingRecords: "Chargement en cours...",
      zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
      emptyTable:     "Aucune donnée disponible dans le tableau",
      paginate: {
          first:      "Premier",
          previous:   "Pr&eacute;c&eacute;dent",
          next:       "Suivant",
          last:       "Dernier"
      },
      aria: {
          sortAscending:  ": activer pour trier la colonne par ordre croissant",
          sortDescending: ": activer pour trier la colonne par ordre décroissant"
      }}
    // language: {
    //     url: "//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
    // },
  }
  labelLongsDropdownSettings:IDropdownSettings ={
  singleSelection: false,
  idField: 'id',
  textField: 'label',
  selectAllText: 'Tous',
  unSelectAllText: 'Réintialiser',
  itemsShowLimit: 2,
  allowSearchFilter: true,
  noDataAvailablePlaceholderText:'Pas de types',
  searchPlaceholderText:'Recherche'
};
  departmentsDropdownSettings:IDropdownSettings ={
    singleSelection: false,
    idField: 'id',
    textField: 'code',
    selectAllText: 'Tous',
    unSelectAllText: 'Réintialiser',
    itemsShowLimit: 7,
    allowSearchFilter: true,
    noDataAvailablePlaceholderText:'Pas du departments',
    searchPlaceholderText:'Recherche'

  };

      // Create a new div element
  private map!: L.Map
  icon_html:any = `<img src="assets/icon/position-marker.svg" style="width: 38px; height: 95px;"><div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">5</div>`
  markers: L.Marker[] =[]
  markerNumbers:L.Marker[]=[] 
  @ViewChild('mapModal') mapModal!: ElementRef ;
  @ViewChild('map') mapElement!: ElementRef;
  
  constructor(private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private interService: IntersService,
    private excelService: ExcelService,
    private labelLongFreeService:LabelLongFreeService,
    private departmentService:DepartmentService,
    private toastService: ToastrService
    ) { }
    ngOnInit(): void {
      this.isLoadedPage = true
      this.locationForm = this.formBuilder.group({
        location: ['', [Validators.required]],
      });
      this.getInterventionFreeDepartments()
      this.getAllLabelLongFree()
    }
  ngAfterViewInit(): void {
  }
setMap(){
  let m = document.getElementById('map-wrapper');
  if(m)
  m.innerHTML='<div id="map" style="width:100%; height:100%"></div>';
  this.initializeMap();
  const map=this.map;
  let markerLatLngs:any[] = [];
  this.markers.forEach((marker:L.Marker,index)=>{this.markerNumbers.push(L.marker(marker.getLatLng(), {icon: this.createMarkerIcon(index+1)}));})
  this.markers.forEach((marker:L.Marker,index)=>{
    //add markers 
    marker.addTo(this.map)
    //add numbers
    this.markerNumbers[index].addTo(map);
    markerLatLngs.push(marker.getLatLng());
});
  
  $('#mapModal').on('shown.bs.modal', function () {
    if (map) {
        map.invalidateSize();

        var bounds = L.latLngBounds(markerLatLngs);
        map.fitBounds(bounds);
    } else {
        console.error("Map object is not initialized or accessible.");
    }
});
}

private initializeMap() {
  this.markers=[]
  this.markerNumbers=[]
  this.itineraires.forEach(itineraire=>{
    const marker = L.marker([itineraire.coordinates.lon,itineraire.coordinates.lat]).bindPopup(itineraire.adresse);
    this.markers.push(marker);
  })
  const baseMapURl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  this.map = L.map('map');
  L.tileLayer(baseMapURl).addTo(this.map);
}
createMarkerIcon(number:any) {
    return L.divIcon({
      className: 'marker-icon',
      html: "<div class='marker-number' style='    font-size: 0.6rem;font-weight: bolder;position: absolute;top: -1.8rem;right: -0.1rem;font-family: math;background-color: #fff;border-radius: 50%;padding: 0.09rem 0.05rem;text-align: center;width: 1rem;margin: auto;height: 1rem;'>" + number + "</div>"
    });
  }
  // private addMarkers() {
  //   this.markers.forEach(marker => marker.addTo(this.map));
  // }
  // for testing 
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  //
  getInterventionFreeDepartments(){
    this.departmentService.getInterventionFreeDepartments().subscribe((deps:Department[])=>{
      this.interventionFreeDepartments=deps;
    },error=>{
      this.toastService.error("Erreur lors de la récupération des départements")
    })
  }
  getAllLabelLongFree(){
    this.labelLongFreeService.getAllLabelLongFrees().subscribe((LabelLongfrees:LabelLongFree[])=>{
      this.labelLongfrees=LabelLongfrees;
    },error=>{
      this.toastService.error("Erreur lors de la récupération des types")
    })
  }

  get f() {
    return this.locationForm.controls;
  }

  async filterItineraire() {
    // this.markers.forEach((marker:L.Marker,index)=>{
    //   this.map.removeLayer(marker)
    //   this.map.removeLayer(this.markerNumbers[index])
    //   console.log('1')
    // })
    let fieldsFilterItineraire: any = {
      position: this.position,
      filterlabelLongs: this.checkRemiseConformite(this.selectedLabelLong.map((label:LabelLongFree)=>label.id)),
      filterDeps: this.selectedDep.map((dep:Department)=>dep.id),
    };
    if (fieldsFilterItineraire.filterlabelLongs?.length != 0 && fieldsFilterItineraire?.filterDeps.length != 0 && fieldsFilterItineraire.position != '') {
      this.isLoadingItin = true;
      this.interService.orderFreesByDistances(fieldsFilterItineraire).subscribe(async (itineraires: InterventionFree[]) => {

        this.isLoadingItin = false;
        this.itineraires = itineraires;
        
      }, (error: any) => {
        this.isLoadingItin = false;
        this.toastService.error("Erreur lors de la récupération des itineraires")
      })
    } else {
      this.isLoadingItin = false;
    }
  }


  saveExcel() {
    this.excelService.exportAsExcelFile(this.fixFreeExcel(), 'itineraire_free');
  }

  fixFreeExcel(): any {
    let itinPreModif = [...this.itineraires]
    return itinPreModif.map((newObject: any) => {
      const downloadedItin = { 'order':itinPreModif.indexOf(newObject)+1,...newObject }
      // downloadedItin.order=itinPreModif.indexOf(newObject)+1
      delete downloadedItin.techs;
      delete downloadedItin.status;
      delete downloadedItin.dateAffecte;
      delete downloadedItin.dateChangeStatus;
      delete downloadedItin.dateCloture;
      delete downloadedItin.dateCreation;
      delete downloadedItin.dateInvalid;
      delete downloadedItin.dateOuverture;
      delete downloadedItin.dateRdv;
      delete downloadedItin.dateRetourFree;
      delete downloadedItin.deadline;
      delete downloadedItin.detailDemande;
      delete downloadedItin.etatLib;
      delete downloadedItin.id;
      delete downloadedItin.idSolution30;
      delete downloadedItin.infra;
      delete downloadedItin.labelCourt;
      delete downloadedItin.nomTechResponsable;
      delete downloadedItin.prenomTechResponsable;
      delete downloadedItin.prestaAffecte;
      delete downloadedItin.prestaAffecteBis;
      delete downloadedItin.recValideParCa;
      delete downloadedItin.refCommande;
      delete downloadedItin.remarques;
      delete downloadedItin.doc1;
      delete downloadedItin.typeDoc1;
      delete downloadedItin.doc2;
      delete downloadedItin.typeDoc2;
      delete downloadedItin.doc3;
      delete downloadedItin.typeDoc3;
      delete downloadedItin.xy;
      return downloadedItin;
    });
  }

  resetItineraire() {
    this.position = "";
    this.selectedLabelLong = [];
    this.selectedDep = [];
    this.itineraires = [];
    this.nonVerifiedFree = [];
    this.verifiedFree = [];
    this.isLoadingItin = false;
  }

  checkRemiseConformite(label: any) {
    let remiseConformitePM = ["Remise en conformité PM (1 à 32 jarretières)",
      "Remise en conformité PM (33 à 64 jarretières)",
      "Remise en conformité PM (65 à 128 jarretières)"];
    if (label.indexOf("Remise en conformité PM") != -1) {
      label.splice(this.selectedLabelLong?.map(label=>label.label).indexOf("Remise en conformité PM"), 1)
      label.push(...remiseConformitePM);
    }
    return label;
  }

  fixLabelLong(element: any): any {
    const remiseConformitePM = ["Remise en conformité PM (1 à 32 jarretières)",
      "Remise en conformité PM (33 à 64 jarretières)",
      "Remise en conformité PM (65 à 128 jarretières)"];

    for (let i = 0; i < element.length; i++) {
      if (remiseConformitePM.includes(element[i])) {
        element[i] = 'Remise en conformité PM';
      }
    }

    let array = Array.from(new Set(element.map((item: any) => item)));
    return array;
  }
}

export const technicianChartOptions ={
    fontFamily:"Inter, sans-serif",
    // series: [
    //   {
    //     name: "Interventions",
    //     data: []
    //   }
    // ],
    chart: {
      height: 350,
      type: "bar"
    },
    // title: {
    //   text: ""
    // },
    // xaxis: {
    //   categories: []
    // },
    plotOptions: {
      bar: {
          horizontal: false,
          hideZeroBarsWhenGrouped: true,
          borderRadius: 5,
          columnWidth: "40%",
          dataLabels: {
              position: "top",
          },
      },
  },
  dataLabels: {
      enabled: false,
  },
  colors: ["#32ab85"],
  };
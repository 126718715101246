import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TokenStorageService } from '../core/services/token-storage.service';
import { IntersService } from '../core/services/inters.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.css']
})
export class PlanningComponent implements OnInit {
  isLoadedPlanning: boolean = false;
  userEmail: string = "";
  abbreviation: string = "";
  role: string = "";
  planFree: any = [];
  dialogRef: any;
  viewedPlanFree: any;
  updatedPlanFree: boolean = false;
  errorMsg: string = "";
  isCheckedStatus: Boolean = false;
  remarques: String = "";
  loading: Boolean = false;

  @ViewChild('planFreeDetailsDialog') planFreeDetailsDialog = {} as TemplateRef<string>;

  constructor(
    private route: Router,
    private intersService: IntersService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private toastService:ToastrService
  ) { }

  ngOnInit(): void {
    this.userEmail = TokenStorageService.getUser().username;
    this.abbreviation = this.userEmail.substring(0, 1).toUpperCase() + this.userEmail.split('.')[1].substring(0, 1).toUpperCase()
    this.loadPlanFree();
  }

  loadPlanFree() {
    let id = TokenStorageService.getUser().id;
    this.intersService.getPlanFreeById(id).subscribe((res: any) => {
      this.planFree = res;
      this.planFree = this.planFree.map((item: any) => {
        item['fixedStatus'] = this.statusBToF(item.status);
        return item
      })
      this.isLoadedPlanning = true;
    }, (err: any) => {
      this.isLoadedPlanning = false;
      this.toastService.error("Erreur lors du chargement du plan de travail");
    })
  }

  viewPlanFree(free: any) {
    this.viewedPlanFree = free;
    // this.keys = ['Type MalFaçon', 'Ref_ticket_hlp', 'Infra', 'Label Court', 'Département', 'REF PTO', 'REF PBO', 'REF PM', 'Adresse PTO', 'Adresse PBO', 'Adresse PM', 'Adresse', 'Statut', 'Détails demande', 'Remarques'];
    this.dialogRef = this.dialog.open(this.planFreeDetailsDialog, {
      data: this.viewedPlanFree, width: '85%', height: '90%', maxWidth: '100%'
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'Confirm') {
        this.loadPlanFree()
      } else if (result == 'Error') {
        this.loadPlanFree()
      }
    });
  }

  updatePlanFree(planFree: any) {
    this.loading=true
    this.intersService.updateFree(planFree).subscribe((response: any) => {
      this.updatedPlanFree = true;
      this.loading=false
      this.remarques = "";
      this.dialogRef.close('Confirm');
      this.toastService.success("Votre action a bien été terminées");
    },
      (error:any) => {
        this.loading=false
        this.errorMsg = error.error.message;
        this.updatedPlanFree = false;
        this.remarques = "";
        this.toastService.error("Erreur lors de la confirmation du l'intervention");
      })
  }

  confirmPlanFreeOk(planFree: any) {
    planFree.status = "REALISE_OK";
    this.updatePlanFree(planFree);
  }

  confirmPlanFreeNok(planFree: any) {
    planFree.status = "REALISE_NOK";
    this.updatePlanFree(planFree);
  }

  statusBToF(status: any): any {
    const listStatusF = ["En attente", "Réussi", "Échec", "Pris par OI"];
    const listStatusB = ["INIT_INTER", "REALISE_OK", "REALISE_NOK", "PRIS_PAR_OI"];
    return listStatusF[listStatusB.indexOf(status)];
  }

  fixRemarques(rmq: any) {
    let finalRemarques = "";
    if (this.remarques !== "") {
      finalRemarques = rmq + '\n' + `Remarque(s) ${this.abbreviation} :  ${this.remarques} `;

    } else {
      finalRemarques = rmq;
    }
    return finalRemarques;
  }

  logOut() {
    TokenStorageService.signOut();
    this.route.navigate(['/']);
    this.toastService.info("Vous êtes déconnecté");
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { environment } from 'src/environments/environment';
import { Department } from '../models/Department';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private http:HttpClient) { }
  private baseUrl = `${environment.BASE_URL}/departments`;

  getInterventionFreeDepartments():Observable<Department[]>{
    return this.http.get<Department[]>(`${this.baseUrl}/interFreeDepartments`)
  }
}

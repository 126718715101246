import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { environment } from 'src/environments/environment';
import { Technician } from '../models/Technician';

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {
  constructor(private http:HttpClient) { }
  private baseUrl = `${environment.BASE_URL}/techs`;

  getAll(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/allTechs`, );
  }
  getActifTechs(): Observable<Technician[]> {
    return this.http.get<Technician[]>(`${this.baseUrl}/actifTechs`, );
  }

  toggleStatus(techId: number, status: boolean): Observable<any> {
    const url = `${this.baseUrl}/toggleStatusTech/${techId}`;
    const body = { id: techId, actif: status };
    return this.http.put<any>(url, body);
  }

  addTech(tech: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/addTechs`, tech, );
  }

  updateTech(updatedTech: any): Observable<any> {
    const url = `${this.baseUrl}/updateTech/${updatedTech.idTech}`;
    return this.http.put<any>(url, updatedTech);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { environment } from 'src/environments/environment';
import { DashboardDistribution } from '../models/Dashboard';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http:HttpClient) { }
  private baseUrl = `${environment.BASE_URL}/dashboard`;
  getDistributions():Observable<DashboardDistribution[]>{
    return this.http.get<DashboardDistribution[]>(`${this.baseUrl}/distributions`)
  }
}

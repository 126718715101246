<app-main-wrapper>
  <div class="w-100  mb-3 pb-4 pt-5"  style="font-size: 0.8rem;">
    <div class=" w-100 mb-2 ps-0 p-2" >
      <span style="color:var(--g1)"><h5>Itineraires free</h5></span>
    </div>
    <div class="card">
      <div class="d-flex w-100">
        <div class="w-100 p-2">
          <ng-multiselect-dropdown style="background-color: var(--c2) !important;"
          [placeholder]="'Les types'"
          [settings]="labelLongsDropdownSettings"
          [data]="labelLongfrees"
          [(ngModel)]="selectedLabelLong"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          [disabled]="isLoadingItin"
        >
        </ng-multiselect-dropdown>
        </div>
        <div class="w-100 p-2">
          <ng-multiselect-dropdown
          [placeholder]="'Les departments'"
          [settings]="departmentsDropdownSettings"
          [data]="interventionFreeDepartments"
          [(ngModel)]="selectedDep"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          [disabled]="isLoadingItin"
        >
        </ng-multiselect-dropdown>
        </div>
        <div class="p-2 d-flex"style="width:50%" >
          <input [disabled]="isLoadingItin" id="positionInput" type="text" placeholder="Location" [(ngModel)]="position" class="w-100">
       </div>
      </div>
      <div class="p-2  d-flex justify-content-end">
          <button class="btn-secondary btn me-3"  [disabled]="isLoadingItin"  (click)="filterItineraire()"  style="font-size: 0.8rem;"><i class="fi fi-bs-filters pe-2"></i>Filtrer </button>
          <button class="btn-secondary btn me-3" [disabled]="isLoadingItin" (click)="saveExcel()"  style="font-size: 0.8rem;"><i class="fi fi-rs-file-excel pe-2"></i> Excel</button>
          <button class="btn-secondary btn" [disabled]="isLoadingItin || !itineraires.length" data-bs-toggle="modal" data-bs-target="#mapModal" (click)="setMap()"  style="font-size: 0.8rem;"><i class="fi fi-bs-world pe-2"></i>Map</button>
      </div>
    </div>   
  </div>
  <div *ngIf="isLoadingItin">
      <span class="loader"></span>
  </div>
  <div *ngIf="!isLoadingItin">
    <div class="p-4 card"  style="font-size: 0.8rem;">
      <table *ngIf="itineraires" [dtOptions]="dtOptions" class="table table-striped table-hover" datatable>
        <thead>
          <tr>
            <th scope="col">Ordre</th>
            <th scope="col">Type MalFaçon</th>
            <th scope="col">Réf Ticket</th>
            <th scope="col">Label Long</th>
            <th scope="col">Label Court</th>
            <th scope="col">Adresse</th>
            <th scope="col">dep</th>
            <th scope="col">Réf PTO</th>
            <th scope="col">Réf PBO</th>
            <th scope="col">Réf PM</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let itin of itineraires; let i = index">
            <td>
              <ng-container>{{ i + 1 }}</ng-container>
            </td>
            <td>{{ itin?.typeMalfacon }}</td>
            <td>{{ itin?.refTicketHlp }}</td>
            <td>{{ itin?.labelLongFree?.label }}</td>
            <td>{{ itin?.labelCourt }}</td>
            <td>{{ itin?.adresse }}</td>
            <td>{{ itin?.department?.code }}</td>
            <td>{{ itin?.refPto }}</td>
            <td>{{ itin?.refPbo }}</td>
            <td>{{ itin?.refPm }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-main-wrapper>
<div class="w-100 modal fade" id="mapModal" tabindex="-1" aria-labelledby="#mapModal" aria-hidden="true">
  <div class="w-100 modal-dialog modal-xl modal-dialog-centered">
    <div class="w-100 modal-content">
    <div id="map-wrapper" class="w-100 modal-body p-2 d-flex flex-column justify-content-between align-items-center" style="height: 80vh;">
        <div id="map" style="width:100%; height:100%"></div>
      </div>
    </div>
  </div>
</div>
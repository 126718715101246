<div class="dropzone_global">
  <div class="dropzone">
    <div class="custom-dropzone">
      <ngx-dropzone class="ngx_dropzone" [multiple]="false" (change)="onSelect($event)">
        <ngx-dropzone-label>
          <div>
            <h2>Charger Le fichier excel</h2>
            <h5>(*.xls, *.csv, *.xlsx)</h5>
          </div>
        </ngx-dropzone-label>
        <ngx-dropzone-preview
          ngProjectAs="ngx-dropzone-preview"
          *ngFor="let f of files"
          [file]="f"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
  </div>
  <div class="submit">
    <button
      [ngStyle]="{ 'background-color': isLoadFacturees ? '#32ab85 ' : 'Grey' }"
      type="button"
      class="btn btn-primary"
      (click)="submit()"
    >
      Soumettre
    </button>
    <button type="button" class="btn btn-primary" (click)="cancel()">
      Annuler
    </button>
  </div>
</div>

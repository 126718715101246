<app-main-wrapper>
      <div class="w-100  mb-3 pb-4 pt-5" style="font-size: 0.8rem;" >
        <div class=" w-100 mb-2 ps-0 p-2 d-flex justify-content-between">
          <span style="color:var(--g1)"><h5>Utilisateurs</h5></span>
          <button  [disabled]="!isLoadedUsers"  class="btn-primary  btn" (click)="openDialog()"  style="font-size: 0.8rem;"><i class="fi fi-rs-plus  pe-1 pt-1"></i><span>Nouveau Utilisateur</span></button>        
          </div>
          <div class="card">
          <div class="p-2  d-flex justify-content-end w-100">
            <button [disabled]="!isLoadedUsers" class="btn-secondary btn" (click)="saveExcel()" style="font-size: 0.8rem;"><i class="fi fi-rs-file-excel pe-2"></i>Excel</button>        </div>
          </div>
      </div>
    <div *ngIf="!isLoadedUsers">
  <span class="loader"></span>
</div>
<div class="table_global" *ngIf="isLoadedUsers ">
  <div    class="card p-4 table-responsive" style="font-size: 0.8rem;">
    <table *ngIf="users" [dtOptions]="dtOptions" class="table table-striped  table-hover" datatable>
      <thead>
        <tr>
          <th>Order</th>
          <th scope="col">Email</th>
          <th scope="col">Role</th>
          <th scope="col">Status</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of allUsers; let i = index">
          <td>
            <ng-container>{{ i + 1 }}</ng-container>
          </td>
          <td>{{ user.email }}</td>
          <td>{{ user?.role?.description }}</td>
          <td><span [ngClass]=" user.status ?'badge-primary':'badge-danger'">{{ mapUserStatus(user.status) }}</span></td>
          <td>
            <div class="actions_buttons">
              <span  (click)="viewUserDetails(user)" class="pe-3 pointer" style="color: var(--c3);"><i class="fi fi-rr-edit"></i></span>
              <span  (click)="onDeleteUser(user)" class="pointer" style="color: var(--c3);"><i class="fi fi-rs-trash"></i></span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</app-main-wrapper>

<ng-template let-data #userDialog>
  <div class="h-100 w-100 d-flex flex-column justify-content-between p-4">
    <div class="p-2 text-center">
      <strong  style="font-size: 0.8rem;"><h3>Modifier utilisateur</h3></strong>
    </div>
    <div class="text-center p-2" style="font-size: 0.9rem;">Saisir les champs pour ajouter un nouveau utilisateur</div>
    <mat-dialog-content>
      <div 
        class="w-100 p-4 pb-0 mb-4"
      >
        <div class="p-1">
              <label for="email" class="form-label" style="color:var(--c3);font-size: 0.8rem;">Email Utilisateur</label>
              <input disabled
              style="font-size: 0.8rem;"
                class="form-control"
                [(ngModel)]="viewedUser.email"
              />
        </div>
        <div class="p-1 w-100">
              <label for="role" class="form-label" style="color:var(--c3) ;font-size: 0.8rem;">Role </label>
              <select
              style="font-size: 0.8rem;"
              class="form-select w-100;"
              [(ngModel)]="viewedUser.role.description"
              name="role"
            >
              <option *ngFor="let role of allRoles" (value)="(role.name)">
                {{ role.description }}
              </option>
            </select>
        </div>
          <div class="form-switch pt-2 pb-2 float-end d-flex">
            <input class="form-check-input "           [(ngModel)]="viewedUser.status" type="checkbox"  id="actif">
            <label class="form-check-label" for="status">
              <span class="ps-3" *ngIf="viewedUser.status"  style="font-size: 0.8rem;">Utilisateur activé</span>
              <span class="ps-3" *ngIf="!viewedUser.status"  style="font-size: 0.8rem;">Utilisateur désactivé</span>
            </label>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <div class="w-50 d-flex justify-content-center">
          <button class="btn-light btn" id="closeDialogBtn"  type="button" mat-dialog-close (click)="onCancelUserDialog()">Annuler</button>
          <button class="btn-confirm btn"   [disabled]="loading"   (click)="saveModifUser(viewedUser)" >Confirmer</button>
          <div *ngIf="loading" class="spinner-square mt-3 ms-2">
            <div class="square-1 square"></div>
            <div class="square-2 square"></div>
            <div class="square-3 square"></div>
          </div>
        </div>
    </div>
    </mat-dialog-content>
  </div>
</ng-template>

<ng-template let-data #userConfirmDeleteDialog>
  <div class="card_title">
    <h2 mat-dialog-title>Details Utilisateur</h2>
  </div>
  <div mat-dialog-content class="card_content">
    <div class="message">
      <span
        >Êtes-vous sûr(e) de vouloir supprimer cet utilisateur ? Cette action
        est irréversible.
      </span>
      <br />
      <br />
      <span>{{ deletedUser.email }} </span>
    </div>
    <br />
  </div>
  <div class="card_buttons">
    <button mat-button class="btn-light btn" (click)="onCancelUserDialog()">
      Annuler
    </button>
    <button
    mat-button
    class="btn-confirm btn"
    (click)="deleteUser(deletedUser)"
  >
    Effacer
  </button>
  </div>
</ng-template>

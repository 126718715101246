import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm!: FormGroup;
  sendEmail = false;
  statusMessage: string = '';
  errorMessage: string = '';
  loading = false;
  appState:string='INIT' //SUCCESS ? ERROR;

  constructor(
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  sendMail() {
    const email = this.forgotPasswordForm.value.email;
    this.loading=true;
    this.authService.initResetPassword(email).subscribe(
      () => {
        this.sendEmail;
        this.loading=false;
        this.appState='SUCCESS';
        // this.toastrService.success('Votre email a bien été envoyé');
      },
      (error: any) => {
        this.loading=false;
        this.appState='ERROR';
        this.errorMessage = error.error.message;
        !this.sendEmail;
        // this.toastrService.error('Votre email n\'a pas été envoyé');
      }
    );
  }
}

import { Component } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation.service';

@Component({
  selector: 'app-in-development',
  templateUrl: './in-development.component.html',
  styleUrls: ['./in-development.component.css']
})
export class InDevelopmentComponent {
  constructor(private navigationService: NavigationService){}

  navigateToUserHomePage(){
    this.navigationService.navigateToUserHomePage();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  submitted = false;
  fieldTextTypePassword: boolean = false;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  role!: string;
  fieldTextTypeEmail: boolean = false;
  siteKey = environment.RECAPTCHA.SITE_KEY;
  captchaValid: boolean = false;
  showPassword = false;

  constructor(private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private authService: AuthService, private tokenStorage: TokenStorageService, private route: Router,
    private toastrService: ToastrService 
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });

  }

  onSubmit(): void {
    const { email, password } = this.loginForm.value;
    this.authService.login(email.toLowerCase(), password).subscribe({
      
      next: data => {

        if (data != null) {
          this.tokenStorage.saveToken(data.accessToken);
          this.toastrService.success('Connexion réussie');          
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.role = TokenStorageService.getUser().roles[0].authority;
          if (this.role == 'ROLE_SU' || this.role == 'ROLE_CA') {
            this.route.navigate(['/dashboard']);
          } else if (this.role == 'ROLE_TECH') {
            this.route.navigate(['/planning']);
          }
        } else {
          this.toastrService.error('Connexion échouée');
        }
      },
      error: err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        this.toastrService.error('Connexion échouée');
      }
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  toggleFieldTextTypeEmail() {
    this.fieldTextTypeEmail = !this.fieldTextTypeEmail;
  }

  toggleFieldTextTypePassword() {
    this.fieldTextTypePassword = !this.fieldTextTypePassword;
  }

  resolved(captchaResponse: string): void {
    this.captchaValid = true;
  }

  onError(errorDetails: any): void {
    this.captchaValid = false;
  }

  reloadPage(): void {
    window.location.reload();
  }
  togglePassword(){
    this.showPassword=!this.showPassword;
  }
}
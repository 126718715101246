import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { TechnicianService } from 'src/app/core/services/technician.service';

@Component({
  selector: 'app-ajout-techs.',
  templateUrl: './ajout-techs.component.html',
  styleUrls: ['./ajout-techs.component.css'],
})
export class AjoutTechsComponent implements OnInit {

  techForm!: FormGroup;
  isAddTech: boolean = false;
  isAddTechFail: boolean = false;
  errorMessage: String = '';
  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AjoutTechsComponent>,
    private techsService: TechnicianService,
    public snackBar: MatSnackBar,
    private toastService: ToastrService
  ) { }

  ngOnInit() {
    this.techForm = this.formBuilder.group({
      codeTech: ['', [Validators.required]],
      prenom: ['', [Validators.required]],
      nom: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onCancelClick(): void {
    this.dialogRef.close('Cancel');
  }

  get f() {
    return this.techForm.controls;
  }


  async submitTech(): Promise<void> {
    this.loading = true;
    const tech = {
      codeTech: this.techForm.value.codeTech,
      prenom: this.techForm.value.prenom,
      nom: this.techForm.value.nom,
      email: this.techForm.value.email,
    };
    let testTech: any = await this.checkExistCodeEmailTech(tech.codeTech, tech.email)
      if (!testTech) {
        if (!this.containLettersSymbols(tech.codeTech) && !this.containNumbers(tech.prenom) && !this.containNumbers(tech.nom)) {
          this.techsService.addTech(tech).subscribe(
            (data: any) => {
              this.isAddTechFail = false;
              this.isAddTech = true;
              this.dialogRef.close('Confirm');
              this.toastService.success("Action terminé avec succès !");
              this.loading = false;
            },
            (error) => {
              this.toastService.error("Erreur lors de l'ajout des Informations Technicien");
              this.dialogRef.close('Error');
              this.errorMessage = error.error.message;
              this.isAddTech = false;
              this.isAddTechFail = true;
              this.loading = false;
            }
          );
        } 
      } else {
        this.dialogRef.close('Error');
        this.toastService.error("Technicien existe déjà !")
      }
  }

  async checkExistCodeEmailTech(code: any, email: any): Promise<boolean> {
    let isExistTech = false;
    let techs: any = await this.techsService.getAll().forEach((items: any) => {
      for (let item of items.content) {
        if (item.codeTech == code || item.email == email) {
          isExistTech = true;
          break;
        }
      }
    }
    );
    return isExistTech;
  }

  containLettersSymbols(code: any) {
    return /[a-zA-Z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(code);
  }

  containNumbers(string: any) {
    return /\d/.test(string);
  }

}

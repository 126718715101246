import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { environment } from 'src/environments/environment';
import { LabelLongFree } from '../models/LabelLongFree';

@Injectable({
  providedIn: 'root'
})
export class LabelLongFreeService {
  constructor(private http:HttpClient) { }
  private baseUrl = `${environment.BASE_URL}/labelLongFree`;

  getAllLabelLongFrees():Observable<LabelLongFree[]>{
    return this.http.get<LabelLongFree[]>(this.baseUrl)
  }
}

import { Injectable } from '@angular/core';
import { TokenStorageService } from './token-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router :Router) { }
  navigateToUserHomePage() {
    const role = TokenStorageService.getRole();
    if(!role){
      this.router.navigate(['/']);
    }
    else{
      if(role === 'ROLE_TECH'){
        this.router.navigate(['/planning']);
      }
      else if(role === 'ROLE_CA' || role === 'ROLE_SU'){
        this.router.navigate(['/dashboard']);
      }
    }
  }
    
}

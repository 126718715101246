import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from 'src/app/core/services/users.service';
import { AjoutUsersComponent } from '../ajout-users/ajout-users.component';
import { ExcelService } from 'src/app/core/services/excel.service';
import { User } from 'src/app/core/models/User';
import { Role } from 'src/app/core/models/Role';
import { ToastrService } from 'ngx-toastr';
import { USER_STATUS_MAP } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-liste-users',
  templateUrl: './liste-users.component.html',
  styleUrls: ['./liste-users.component.css']
})
export class ListeUsersComponent implements OnInit {
  users: User[] = [];
  isLoadedUsers: boolean = false;
  allUsers: User[] = [];
  viewedUser!: User;
  deletedUser!: User;
  dialogRef: any;
  initModifUser: boolean = false;
  modifiedUser: boolean = false;
  allRoles: Role[] = [];
  errorMessage: String = '';
  loading: boolean = false;

  @ViewChild('userDialog') userDialog = {} as TemplateRef<string>;
  @ViewChild('userConfirmDeleteDialog') userConfirmDeleteDialog = {} as TemplateRef<string>;

  dtOptions: DataTables.Settings = {
    // pageLength: 5,
    paging:false,
    lengthChange: false,
    ordering: false,
    language: {
      processing:     "Traitement en cours...",
      search:         "Rechercher&nbsp;:",
      lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
      info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
      infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
      infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
      infoPostFix:    "",
      loadingRecords: "Chargement en cours...",
      zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
      emptyTable:     "Aucune donnée disponible dans le tableau",
      paginate: {
          first:      "Premier",
          previous:   "Pr&eacute;c&eacute;dent",
          next:       "Suivant",
          last:       "Dernier"
      },
      aria: {
          sortAscending:  ": activer pour trier la colonne par ordre croissant",
          sortDescending: ": activer pour trier la colonne par ordre décroissant"
      }}
  }
  constructor(
    private usersService: UsersService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private excelService: ExcelService,
    private toastService: ToastrService
  ) { }

  ngOnInit() {
    this.loadUsers();
  }

  mapUserStatus(status: boolean):string {
    return USER_STATUS_MAP[status+'']; 
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AjoutUsersComponent, {
      width: '35%',
      height: '50%',
    });
  }

  loadAllRoles() {
    this.usersService.getAllRoles().subscribe((res: any) => {
      this.allRoles = res.content;
    }, (errour: any) => {
      this.toastService.error("Error en chargement des rôles");    
    })
  }

  loadUsers() {
    this.usersService.getAllUsers().subscribe(
      (response: any) => {
        this.allUsers = response.content;
        this.isLoadedUsers = true;
        this.users = this.allUsers;
      },
      (error: any) => {
        this.toastService.error("Error en chargement des utilisateurs");
        this.isLoadedUsers = false;
      }
    );
  }

  saveExcel() {
    this.excelService.exportAsExcelFile(this.fixUsersExcel(), 'liste_users');
  }

  fixUsersExcel(): any {
    let usersPreModif = [...this.users]
    return usersPreModif.map((newObject: any) => {
      const downloadedUser = { ...newObject }
      delete downloadedUser.password;
      delete downloadedUser.passwordResetToken;
      downloadedUser.role = downloadedUser.role.description;
      if (downloadedUser.status == true) {
        downloadedUser.status = 'Actif'
      } else {
        downloadedUser.status = 'Inactif'
      }
      if (downloadedUser.techs != null) {
        downloadedUser.techs = downloadedUser.techs.prenom + ' ' + downloadedUser.techs.nom
      }
      return downloadedUser;
    });
  }

  viewUserDetails(user: any) {
    this.loadAllRoles();
    this.viewedUser = user;
    this.dialogRef = this.dialog.open(this.userDialog,
      { data: this.viewedUser, height: '50%', width: '30%' });
  }

  onDeleteUser(user: any){
    this.deletedUser = user;
    this.dialogRef = this.dialog.open(this.userConfirmDeleteDialog,
      { data: this.viewedUser, height: '30%', width: '30%' });
  }
  deleteUser(user: any) {
      console.log("user:",user)
      this.dialogRef.close('Confirm');
        this.usersService.deleteUserById(user.id).subscribe((res: any) => {
         this.toastService.success("Utilisateur supprimé avec succès");
          this.loadUsers()
        });
    //   }
    // }, 
    (error: any) => {
      this.toastService.error("Erreur lors de la suppression de l'utilisateur");
    }
    // )
  }

  saveModifUser(user: any) {
    this.loading=true
    const updateUser = {
      id: user.id,
      role: this.updateRole(user.role),
      status: user.status
    }

    this.usersService.updateUser(updateUser).subscribe((response: any) => {
      this.dialogRef.close('Confirm');
      this.modifiedUser = true;
      this.initModifUser = true;
      this.loading=false
      this.loadUsers();
      this.toastService.success("Utilisateur modifié avec succès");

    }, (error: any) => {
      this.toastService.error("Erreur lors de la modification de l'utilisateur");
      this.errorMessage = error.error.message;
      this.loading=false
      this.modifiedUser = false;
      this.initModifUser = false;
    })
  }

  updateRole(role: any): any {
    let updatedRole: any;
    for (let element of this.allRoles) {
      if (element.description === role.description) {
        updatedRole = element;
        break;
      }
    }
    return updatedRole
  }

  modifUser() {
    this.initModifUser = true;
  }

  confirmDeleteDialogue() {
    this.dialogRef.close('Confirm');
  }

  cancelDeleteDialogue() {
    this.dialogRef.close();
  }

  onCancelUserDialog() {
    this.initModifUser = false
    this.modifiedUser = false;
    this.dialogRef.close();
  }
}

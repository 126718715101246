import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/core/models/Role';
import { Technician } from 'src/app/core/models/Technician';
import { TechnicianService } from 'src/app/core/services/technician.service';
import { UsersService } from 'src/app/core/services/users.service';

@Component({
  selector: 'app-ajout-users',
  templateUrl: './ajout-users.component.html',
  styleUrls: ['./ajout-users.component.css']
})
export class AjoutUsersComponent implements OnInit {
  userForm!: FormGroup;
  isAddUserFail: boolean = false;
  isAddUser: boolean = false;
  errorMessage: String = "";
  allRoles: Role[] = [];
  allTechs: Technician[] = [];
  loading:boolean=false

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AjoutUsersComponent>,
    public snackBar: MatSnackBar,
    private usersService: UsersService,
    private techsService: TechnicianService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', [Validators.required]],
      tech: [''],
    });
    this.loadAllRoles();
    this.loadAllTechs();
    
  }

  onCancelClick(): void {
    this.dialogRef.close('Cancel');
  }

  get f() {
    return this.userForm.controls;
  }


  loadAllTechs() {
    this.techsService.getAll().subscribe((res: any) => {
      this.allTechs = res.content;
    }, (errour: any) => {
      this.toastService.error("Erreur lors de la chargment des techniciens");
    })
  }

  loadAllRoles() {
    this.usersService.getAllRoles().subscribe((res: any) => {
      this.allRoles = res.content;
    }, (errour: any) => {
      this.toastService.error("Erreur lors de la chargment des rôles");
    })
  }

  async submitUser(): Promise<void> {
    this.loading = true;
    const user = {
      email: this.userForm.value.email,
      role: this.userForm.value.role,
      tech: this.userForm.value.tech,
    };
    let testUser: any = await this.checkExistEmailUser(user.email)
      if (!testUser) {
        this.usersService.addUser(user).subscribe(
          (data: any) => {
            this.isAddUserFail = false;
            this.isAddUser = true;
            this.dialogRef.close('Confirm');
            this.toastService.success("Utilisateur ajouté avec succès");
            this.loading = false;
          },
          (error: any) => {
            this.dialogRef.close('Error');
            this.errorMessage = error.message;
            this.isAddUser = false;
            this.isAddUserFail = true;
            this.toastService.error("Erreur lors de l'ajout de l'utilisateur");
            this.loading = false;
          }
        );
      } else {
        this.toastService.error("Erreur lors de l'ajout de l'utilisateur","Utilisateur existe déjà");
      }
  }

  async checkExistEmailUser(email: any): Promise<boolean> {
    let isExistUser = false;
    let users: any = await this.usersService.getAllUsers().forEach((items: any) => {
      for (let item of items.content) {
        if (item.email == email) {
          isExistUser = true;
          break;
        }
      }
    }
    );
    return isExistUser;
  }
  onMarketChange(event: any) {

  }




}

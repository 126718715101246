import { Injectable } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private service: TokenStorageService, private route: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
      if (this.service.IsLogged !== true) {
      this.route.navigate([''])
    }
    return true;
  }

}

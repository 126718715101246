import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IntersService } from '../core/services/inters.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Department } from 'src/app/core/models/Department';
import { technicianChartOptions } from './chartsConfigs';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle
} from "ng-apexcharts";
import { DashboardService } from '../core/services/dashboard.service';
import { DashboardDistribution } from '../core/models/Dashboard';
import { DepartmentService } from '../core/services/department.service';
import { ToastrService } from 'ngx-toastr';
import { error } from 'jquery';
import { DatePipe } from '@angular/common';
import { TokenStorageService } from '../core/services/token-storage.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
    allFree: any = [];
  initFree: any = [];
  echecFree: any = [];
  successFree: any = [];
  poiFree: any = [];
  isLoadedFree: boolean = false;
  dataFree: any[] = [];
  actualMonthFree: any = [];
  previousMonthsFree: any = [];
  previousYearFree: any = [];
  techStatPreviousYear: any = [];
  currentMonth!: string;
  previousMonth!: string;
  previousYear!: number;
  actualYear!: number;
  sortNbr: boolean = false;

  statusDistribution: any =[];
  techniciansDistribution: any =[]
  numberOfTechnicians:number=0
  numberOfInterventions:number=0
  numberOfDepartments:number=0

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  public chartTechnicianOptions!: any  
  public chartStatusOptions!: any  
  currentDateAndTime: Date=new Date();
  formattedDate!: string;
  months: string[] = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

  constructor(public router: Router, public snackBar: MatSnackBar,
    private dashboardService:DashboardService,
    private intersService: IntersService,
    private departmentService:DepartmentService,
    private toastrService: ToastrService,
    // private datePipe: DatePipe,
  ) { 
      this.chartTechnicianOptions = technicianChartOptions
      this.chartStatusOptions = technicianChartOptions
}
username!:string
  ngOnInit() {
    this.getNumberOfDepartments()
    this.username =TokenStorageService.getUser().username;
    // this.currentDateAndTime ??= this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    // const day = this.currentDateAndTime[2];
    // const month = this.currentDateAndTime[1];
    // const year = this.currentDateAndTime[0];

    // const frenchMonth = this.months[parseInt(month) - 1];

    // this.formattedDate = `${day} ${frenchMonth} ${year}`;
  }
  getNumberOfDepartments(){
    this.departmentService.getInterventionFreeDepartments().subscribe((deps:Department[])=>{
      this.numberOfDepartments=deps.length
    })
  }

  getDistributions(){
    this.dashboardService.getDistributions().subscribe((data:DashboardDistribution[])=>{
      this.techniciansDistribution=data[0]
      this.statusDistribution=data[1]
      this.techniciansDistribution.keys.forEach((k:number)=>{
        this.numberOfTechnicians+=1
      })
      this.statusDistribution.values.forEach((v:number)=>{
        this.numberOfInterventions+=v
      })
           this.chartTechnicianOptions = {
      fontFamily: "Inter, sans-serif",
      series: [
        {
          name: "Interventions",
          data: this.techniciansDistribution.values
        }
      ],
      chart: {
        height: 350,
        type: "bar"
      },
      title: {
        text: this.techniciansDistribution.title
      },
      xaxis: {
        categories: this.techniciansDistribution.keys
      },
      plotOptions: {
        bar: {
            horizontal: false,
            hideZeroBarsWhenGrouped: true,
            borderRadius: 5,
            columnWidth: "40%",
            dataLabels: {
                position: "top",
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    colors: ["#32ab85"],
    };;
    this.chartStatusOptions=
    {
      fontFamily: "Inter, sans-serif",
      series: [
        {
          name: "Interventions",
          data: this.statusDistribution.values
        }
      ],
      chart: {
        height: 350,
        type: "bar"
      },
      title: {
        text: this.statusDistribution.title
      },
      xaxis: {
        categories: this.statusDistribution.keys
      },
      plotOptions: {
        bar: {
            horizontal: false,
            hideZeroBarsWhenGrouped: true,
            borderRadius: 5,
            columnWidth: "40%",
            dataLabels: {
                position: "top",
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    colors: ["#32ab85"],
    };;

    },error=>{
      this.toastrService.error("Erreur lors de la récupération des données")
    })
  }

  ngAfterViewInit(){

    setTimeout(() => {
      this.getDistributions();
  }, 1);
  }

  getStatPreviousYear() {
    this.intersService.getStatPreviousYear().subscribe((response: any) => {

      this.previousYearFree = response;
      this.techStatPreviousYear = this.getTechniciensStats(this.previousYearFree);
    }, (error: any) => {
      this.toastrService.error("Erreur lors de la récupération des données")
    })
  }
  getTechniciensStats(missions: any[]): any {
    const interventionsMap: { [key: string]: number } = {};

    missions.forEach((item: any) => {
      let techFullName = `${item.techs.prenom} ${item.techs.nom}`;
      if (techFullName !== undefined && techFullName !== null) {
        interventionsMap[techFullName] = (interventionsMap[techFullName] || 0) + 1;
    }
    });
    return interventionsMap;
 
  }

  getCurrentAndPreviousMonth() {
    const today = new Date();
    this.actualYear = today.getFullYear();
    this.previousYear = today.getFullYear() - 1;
    this.currentMonth = this.months[today.getMonth()] + ' ' + (today.getFullYear()).toString();

    if (today.getMonth() === 0) {
      // If the current month is January, set the previous month to December of the previous year
      this.previousMonth = this.months[11] + ' ' + (today.getFullYear() - 1).toString();
    } else {
      this.previousMonth = this.months[today.getMonth() - 1] + (today.getFullYear()).toString();
    }
  }

  sortPricePreviousFree() {

  }

  sortNbrPreviousYearFree() {
    if (this.sortNbr == true) {
      this.techStatPreviousYear.sort((a: any, b: any) => a.nbr - b.nbr);
      this.sortNbr = false;
    } else {
      this.techStatPreviousYear.sort((a: any, b: any) => b.nbr - a.nbr)
      this.sortNbr = true;
    }
  }


  loadFree() {
    this.intersService.getAllFree().subscribe(
      (response: any) => {
        this.allFree = response.content;

        this.allFree.map((item: any) => {
          item.dateCreation = this.filterDate(item.dateCreation);
          item.dateOuverture = this.filterDate(item.dateOuverture);
          item.dateRdv = this.filterDate(item.dateRdv);
          item.deadline = this.filterDate(item.deadline);
          item.dateRetourFree = this.filterDate(item.dateRetourFree);
          item.dateCloture = this.filterDate(item.dateCloture);
          item.dateInvalid = this.filterDate(item.dateInvalid);
          item.dateAffecte = this.filterDate(item.dateAffecte);
          item.dateChangeStatus = this.filterDate(item.dateChangeStatus);
          item['nomPrenomTech'] = this.fixNomPrenom(item.techs?.prenom, item.techs?.nom);
          item['fixedStatus'] = this.statusBToF(item.status);

          if (item.status == 'INIT_INTER') {
            this.initFree.push(item);
          } else if (item.status == 'REALISE_OK') {
            this.successFree.push(item);
          } else if (item.status == 'REALISE_NOK') {
            this.echecFree.push(item);
          } else {
            this.poiFree.push(item)
          }
          return item
        });

        this.dataFree = [
          { name: 'En attente', y: Number((this.initFree.length / this.allFree.length * 100).toFixed(2)) },
          { name: 'Echec', y: Number((this.echecFree.length / this.allFree.length * 100).toFixed(2)) },
          { name: 'Réussi', y: Number((this.successFree.length / this.allFree.length * 100).toFixed(2)) },
          { name: 'Pris Par OI', y: Number((this.poiFree.length / this.allFree.length * 100).toFixed(2)) }
        ]
        this.isLoadedFree = true;

      },
      (_error: any) => {
        this.toastrService.error("Erreur lors de la récupération des données")
        this.isLoadedFree = false;
      }
    );
  }

  filterDate(date: any) {
    if (date != null) {
      if (date.substr(0, 10) == "1970-01-01" || date.substr(0, 10) == "1969-12-31") {
        return "";
      }
      return date.substr(0, 10);
    } else {
      return ""
    }
  }

  statusBToF(status: any): any {
    const listStatusF = ["En attente", "Réussi", "Échec", "Pris par OI"];
    const listStatusB = ["INIT_INTER", "REALISE_OK", "REALISE_NOK", "PRIS_PAR_OI"];
    return listStatusF[listStatusB.indexOf(status)];
  }

  fixNomPrenom(prenom: any, nom: any) {
    let nomPrenom: string = "";
    if (typeof (prenom) === 'string' || typeof (nom) === "string") {
      nomPrenom = prenom + " " + nom;
    }
    return nomPrenom
  }
}

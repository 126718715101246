import { Component } from '@angular/core';
import { IntersService } from 'src/app/core/services/inters.service';
import * as XLSX from 'xlsx';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ajout-realisees',
  templateUrl: './ajout-realisees.component.html',
  styleUrls: ['./ajout-realisees.component.css'],
})
export class AjoutRealiseesComponent {
  files: File[] = [];
  filename: string = '';
  type: boolean = false;
  isloadRealisees: boolean = false;
  isAddRealisees: boolean = false;
  data: any = [];
  date: string = '';
  isAddRealiseesFail: boolean = true;
  errorMessage: String = '';

  constructor(
    public dialogRef: MatDialogRef<AjoutRealiseesComponent>,
    private inters: IntersService,
  ) { }

  // ngOnInit(): void {}

  onSelect(event: any) {
    this.type = !!event.addedFiles[0].name.match(/(.xls|.xlsx|.csv)/);

    if (this.files.length == 0) {
      this.files.push(...event.addedFiles);
      this.filename = event.addedFiles[0].name;

      if (this.files.length) {
        const file = this.files[0];
        const reader = new FileReader();
        reader.onload = (event: any) => {
          const wb = XLSX.read(event.target.result);
          const sheets = wb.SheetNames;

          if (sheets.length!=0) {
            const row = XLSX.utils.sheet_to_json(wb.Sheets[sheets[0]]);
            this.isloadRealisees = true;
            this.data = JSON.stringify(row);
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  }

  cancel(): void {
    this.dialogRef.close('Cancel');
  }

  submit() {
    if (this.isloadRealisees) {
      const body = {
        data: this.data,
        filename: this.filename,
      };
      this.inters.uploadRealisees(body).subscribe(
        (response: any) => {
          this.isAddRealisees = true;
          this.isAddRealiseesFail = false;
          this.dialogRef.close('Confirm');
        },
        (error) => {
          this.errorMessage = error.error.message;
          this.isAddRealisees = false;
          this.isAddRealiseesFail = true;
        }
      );
    }
  }

  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.isloadRealisees = false;
    this.isAddRealisees = false;
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppMaterialModule } from "./app.material-module";
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { DataTablesModule } from 'angular-datatables';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgApexchartsModule } from "ng-apexcharts"
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { AjoutTechsComponent } from './techs/ajout-techs/ajout-techs.component';
import { ListeTechsComponent } from './techs/liste-techs/liste-techs.component';
import { AjoutFactureesComponent } from './inters-facturees/ajout-facturees/ajout-facturees.component';
import { ListeFactureesComponent } from './inters-facturees/liste-facturees/liste-facturees.component';
import { ListeRealiseesComponent } from './inters-realisees/liste-realisees/liste-realisees.component';
import { AjoutRealiseesComponent } from './inters-realisees/ajout-realisees/ajout-realisees.component';
import { ComparisonComponent } from './comparison/comparison.component';
import { ListeFreeComponent } from './inters-free/liste-free/liste-free.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LoginComponent } from './connexion/login/login.component';
import { ForgotPasswordComponent } from './connexion/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './connexion/reset-password/reset-password.component';
import { CommonModule } from '@angular/common';
import { IteneraireFreeComponent } from './inters-free/iteneraire-free/iteneraire-free.component';
import { MatSlideToggleModule, _MatSlideToggleRequiredValidatorModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { ListeUsersComponent } from './users/liste-users/liste-users.component';
import { AjoutUsersComponent } from './users/ajout-users/ajout-users.component';
import { PlanningComponent } from './planning/planning.component';
import { GeoapifyGeocoderAutocompleteModule } from '@geoapify/angular-geocoder-autocomplete';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component';
import { ForbiddenPageComponent } from './shared/components/forbidden-page/forbidden-page.component';
import { InDevelopmentComponent } from './shared/components/in-development/in-development.component';
import { MainWrapperComponent } from './shared/components/main-wrapper/main-wrapper.component';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    AjoutTechsComponent,
    ListeTechsComponent,
    AjoutFactureesComponent,
    ListeFactureesComponent,
    ListeRealiseesComponent,
    AjoutRealiseesComponent,
    ComparisonComponent,
    ListeFreeComponent,
    FooterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    IteneraireFreeComponent,
    ListeUsersComponent,
    AjoutUsersComponent,
    PlanningComponent,
    DashboardComponent,
    NotFoundPageComponent,
    ForbiddenPageComponent,
    InDevelopmentComponent,
    MainWrapperComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDropzoneModule,
    BrowserAnimationsModule,
    NgbModule,
    AppMaterialModule,
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    MatTabsModule,
    DataTablesModule,
    CommonModule,
    MatSlideToggleModule,
    _MatSlideToggleRequiredValidatorModule,
    MatButtonModule,
    RecaptchaModule,
    GeoapifyGeocoderAutocompleteModule.withConfig('a0ee1bfe2fec4f708346ab94ff90fcbe'),
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    NgApexchartsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbCollapseModule,
    ToastrModule.forRoot({      
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,}
    ),
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../../core/services/token-storage.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [DatePipe]
})
export class NavbarComponent implements OnInit {
  username = "";
  role = "";
  currentDateAndTime: any;
  monthNames = [
    "janvier", "février", "mars", "avril", "mai", "juin",
    "juillet", "août", "septembre", "octobre", "novembre", "décembre"
  ];
  formattedDate!: string;
  toogleSideBar:boolean = true;

  constructor(private route: Router,
      private datePipe: DatePipe,
      private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.role = TokenStorageService.getUser().roles[0];
    this.username =TokenStorageService.getUser().username;
    this.currentDateAndTime ??= this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.split('-');

    const day = this.currentDateAndTime[2];
    const month = this.currentDateAndTime[1];
    const year = this.currentDateAndTime[0];

    const frenchMonth = this.monthNames[parseInt(month) - 1];

    this.formattedDate = `${day} ${frenchMonth} ${year}`;

    let sidebar = document.querySelector('.sidebar');
    let closeBtn = document.querySelector('#btn');

    closeBtn?.addEventListener('click', () => {
      sidebar?.classList.toggle('open');
    });
  }

  signOut() {
    TokenStorageService.signOut();
    this.route.navigate(['/']);
    // this.toastrService.info("Vous êtes déconnecté");
  }
  toogleSideBarContent(){
    this.toogleSideBar=!this.toogleSideBar;
  }
}

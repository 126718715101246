import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor() {}

  public static signOut(): void {
    window.sessionStorage.clear();
    window.sessionStorage.removeItem(TOKEN_KEY);
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public static getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public static getUser(): any {
    const token= this.getToken();
    if (token) {
      const parsedData=this.decodeToken(token)
      return {
        username: parsedData.sub,
        roles: parsedData.roles,
        id: parsedData.userId
      }
    }
    return {};
  }

  public get IsLogged(): boolean {
    let authToken = window.sessionStorage.getItem(TOKEN_KEY);
    return authToken !== null ? true : false;
  }
  public static getRole():string{
    const token= this.getToken()
    if(token){
      const parsedData=this.decodeToken(token)
      return parsedData.roles[0].authority;
    }
    return '';
    
  }
  private static decodeToken(token: string): any {
    const decodedData = token.split('.')[1];
    return JSON.parse(window.atob(decodedData));
  }
}
